import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from 'src/app/api/authentication.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public static loading: boolean = false;
  constructor(private authService: AuthenticationService) {}
  ngOnInit(): void {
  }
  public static showLoader() {
    if (AppComponent.loading != true) {
      AppComponent.loading = true;

      setTimeout(() => {
        AppComponent.hideLoader();
      }, 1000);
    }
  }

  public static hideLoader() {
    if (AppComponent.loading != false) {
      AppComponent.loading = false;
    }
  }

  getLoading() {
    return AppComponent.loading;
  }
}
