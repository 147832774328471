// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class RoutingResolveService {

//   constructor() { }
// }


// routing-resolve.service.ts




import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { StatusComponent } from '../ui/status/status.component';
import { HomeComponent } from '../ui/status/home/home.component';
import { Home2Component } from '../ui/status/home2/home.component';
import { HistoryComponent } from '../ui/status/history/history.component';
import { EventDetailComponent } from '../ui/status/event-detail/event-detail.component';
import { SubscriptionComponent } from '../ui/status/subscription/subscription.component';
import { Home3Component } from '../ui/status/home3/home3.component';
import { Home4Component } from '../ui/status/home4/home4.component';
import { CustomRoutesServiceService } from './CustomRoutesService.service';
import { CookieService } from 'ngx-cookie-service';
import { sharedService } from './sharedService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RoutingResolve implements Resolve<any> {
  constructor(private router: Router,
    private customRoutesSvc: CustomRoutesServiceService,
    private cookieService: CookieService,
    private sharedService: sharedService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    const currentTheme = this.customRoutesSvc.getTheme();
    let newRoutes;
    //Modify the router configuration based on the current theme
    if (currentTheme === 'Aurora') {
      newRoutes = [
            { path: '', component: Home4Component },

      ];
    } else if (currentTheme === 'Bluelake') {
      newRoutes = [
            { path: '', component: HomeComponent },
      ];
    } else {
      newRoutes = [
        { path: '', component: Home3Component },
      ];
    }

    // Replace the existing routes with the new dynamically generated routes
    this.router.resetConfig(newRoutes);

    return currentTheme; // Since we are not actually resolving any data, return null.
  }

  // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
  //   return this.sharedService.getTheme().pipe(
  //     // You can use the map operator to transform the theme value and return it synchronously
  //     map((currentTheme) => {
  //       let newRoutes;

  //       // Modify the router configuration based on the current theme
  //       if (currentTheme === 'Aurora') {
  //         newRoutes = [{ path: '', component: Home4Component }];
  //       } else if (currentTheme === 'Bluelack') {
  //         newRoutes = [{ path: '', component: HomeComponent }];
  //       } else {
  //         newRoutes = [{ path: '', component: Home3Component }];
  //       }
  //       return { currentTheme };

  //       // Replace the existing routes with the new dynamically generated routes
  //       this.router.resetConfig(newRoutes);

  //       // Return any data you want to pass along with the resolved theme (if needed)
  //     })
  //   );
  // }
}
