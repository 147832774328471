import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Incident, Region,Zone, StatusPageModel } from 'src/app/models/StatusPageModel';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Observable, timer } from 'rxjs'; 
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home3',
  templateUrl: './home3.component.html',
  styleUrls: ['./home3.component.css']
})
export class Home3Component implements OnInit {
  currentTime: Date;
  secondsRemaining: number;
  isDropdownOpen: boolean = false;
  showingPopup: boolean = false;
  popupDate: string = '';
  statusData: StatusPageModel;
  zones: Zone[];
  regions: Region[];
  activeAnnouncements: Incident[];
  refreshClock: any;
  incidents: Incident[];
  refreshInterval: string = "2";
  timeZone;
  $currentDateTime: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));
  refreshOptions: Array<Object> = [
    { name: '2 Minutes', value: "2" },
    { name: '5 Minutes', value: "5" },
    { name: '10 Minutes', value: "10" }
  ]
  datarange: Array<Object> = [
    { name: '30 Days', value: "30" },
    { name: '60 Days', value: "60" },
    { name: '90 Days', value: "89" }
  ];
  selectedOption: string = ""; 
  rectangles: number[] = []; 
  daysToShow: number = 90; 
  uptimePercent: number = 100;
  
  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private _statusApiService: StatusApiService) {
    this.timeZone = Globals.timeZone;
  }

  ngOnInit(): void {
    
    this.getComponentStatus(true);
    this.getActiveAnnouncments();
    this.setUpdateRefreshClock();

    if (this.selectedOption === "") {
      this.selectedOption = "89";
    }
    this.updateRectangles(); 
  }
  
 updateRectangles() {
  const totalDays = 89;
  const selectedDays = parseInt(this.selectedOption);

  if (selectedDays === totalDays) {
    this.rectangles = Array(totalDays).fill(0).map((_, index) => index);
    this.daysToShow = 90;
  } else if (selectedDays === 60) {
    this.rectangles = Array(totalDays - 30).fill(0).map((_, index) => index + 30);
    this.daysToShow = 60;
  } else if (selectedDays === 30) {
    this.rectangles = Array(totalDays - 60).fill(0).map((_, index) => index + 60);
    this.daysToShow = 30;
   }
}
  
  refreshRateChange() {
    this.setUpdateRefreshClock();
  }

  setUpdateRefreshClock() {
    if (this.refreshClock)
      clearInterval(this.refreshClock);

    this.refreshClock = setInterval(() => {
      //console.log('executed : ' + this.refreshInterval + " " + new Date());
      this.getComponentStatus();
    }, parseInt(this.refreshInterval) * 1000 * 60);
  }
  toggleFullScreen() {
    if (this.document.fullscreenElement) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      }
    } else {
      if (this.document.documentElement.requestFullscreen) {
        this.document.documentElement.requestFullscreen();
      }
    }
  }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  showPopup(event: MouseEvent, index: number) {
    const currentDate = new Date();
    const utcDate = currentDate.getUTCDate();
    const hoveredDate = new Date(currentDate.getTime() - ((89 - index) * 24 * 60 * 60 * 1000));
    const hoveredUtcDate = hoveredDate.getUTCDate();

    this.popupDate = hoveredUtcDate === utcDate ? hoveredDate.toISOString().slice(0, 10) : new Date(hoveredDate.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10);
    const delayTime = 50; 

    // Show the popup after the specified delay
    setTimeout(() => {
      this.showingPopup = true;
      const popup = document.getElementById('popup');
      popup.style.top = `${event.clientY}px`;
      popup.style.left = `${event.clientX}px`;
    }, delayTime);
  }
  hidePopup() {
    this.showingPopup = false;
  }
  getComponentStatus(updateRegionZones = false) {
    this._statusApiService.GetComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            if (updateRegionZones)
              this.upadteZonesFromStatusData();
          }
        },
        error => {
          console.log(error);
        }
      );
  }
  upadteZonesFromStatusData() {
    let totalZones: Zone[] = [];
    this.statusData.regions.forEach((r) => {
      r.zones.forEach((z) => {
        let zone: Zone = {
          regionId: r.regionId,
          regionName: r.regionName,
          sequence: z.sequence,
          zoneId: z.zoneId,
          zoneName: z.zoneName
        };
        totalZones.push(zone);
      });
    });
    this.zones = totalZones;
    this.regions = this.statusData.regions;
  }
  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
        },
        error => {
          console.log(error);
        }
      );
  }
 
 }


