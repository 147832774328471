import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { StatusComponent } from './status.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StatusApiService } from '../../api/statusapi.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { Home2Component } from './home2/home.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { environment } from '../../../environments/environment';
import { CGRZChecklistDatabase } from '../../models/CGRZCheckListDatabase';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { SearchPipe } from '../../searchFilter';
import { EventDetailDialogComponent } from './event-detail-dialog/event-detail-dialog.component';
import { Home3Component } from './home3/home3.component';
import { Home4Component } from './home4/home4.component';
import { RoutingResolve } from 'src/app/api/routingResolve.service';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { SingleRegionHomeComponent } from './single-region-home/single-region-home.component';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { StatusCustomExpansionPanelComponent } from './status-custom-expansion-panel/status-custom-expansion-panel.component';
import { UserInvitationComponent } from './user-invitation/user-invitation.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


const routes: Routes = [
  {
  path: '',
     component: StatusComponent,
     children:[
       //{path: '', component:HomeComponent, resolve: { routing: RoutingResolve } },
      { path: '', component: changeHomeStatusPage(), resolve: { routing: RoutingResolve }},
      { path: 'default', component: SingleRegionHomeComponent, resolve: { routing: RoutingResolve }},
      { path: 'itechopsstatushome', component: Home2Component },
      //{ path: 'new', component: HomeComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'event-detail/:id', component: EventDetailComponent },
      { path: 'event-detail', component: EventDetailComponent },
      { path: 'subscription/:id', component: SubscriptionComponent },
      { path: 'home3', component: Home3Component , resolve:{routing:RoutingResolve}},
      { path: 'home4', component: Home4Component,resolve:{routing:RoutingResolve} },
      { path: 'signin',component: SigninComponent},
      { path: 'signup',component: SignupComponent},
      { path: 'signup/:id',component: SignupComponent},
      { path: 'user-invitation',component: UserInvitationComponent},
      { path: 'user-invitation/:id',component: UserInvitationComponent},
      { path: 'report-issue',component: ReportIssueComponent},
      { path: 'forgotpassword',component: ForgotpasswordComponent},
      { path: 'changepassword',component: ChangePasswordComponent},
      { path: 'changepassword/:id',component: ChangePasswordComponent},


     ]
  }
];

@NgModule({
  declarations: [
    StatusComponent,
    NavMenuComponent,
    HomeComponent,
    Home2Component,
    Home3Component,
    HistoryComponent,
    EventDetailComponent,
    SubscriptionComponent,
    SearchPipe,
    EventDetailDialogComponent,
    Home4Component,
    SigninComponent,
    SignupComponent,
    ReportIssueComponent,
    SingleRegionHomeComponent,
    StatusCustomExpansionPanelComponent,
    UserInvitationComponent,
    ForgotpasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DataTablesModule,
    NgxTippyModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule
  ],
  providers: [
    StatusApiService,
    CGRZChecklistDatabase,
    RoutingResolve
  ],
  bootstrap: [StatusComponent],
})
export class StatusModule {

 }


 function changeHomeStatusPage(){
  const theme = getCookieValue('theme');
  if(theme && theme !== 'Default')
    return HomeComponent;
  else
    return SingleRegionHomeComponent;
 }

 function getCookieValue(name)
    {
      const regex = new RegExp(`(^| )${name}=([^;]+)`)
      const match = document.cookie.match(regex)
      if (match) {
        return match[2]
      }
   }
