import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { UserService } from 'src/app/api/user.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;

  isValidFormSubmitted = null;


  SignUpForm = new UntypedFormGroup({
    UserId: new UntypedFormControl(''),
    FirstName: new UntypedFormControl(''),
    LastName: new UntypedFormControl(''),
    Username: new UntypedFormControl(''),
    PhoneNumber: new UntypedFormControl(''),
    Password: new UntypedFormControl(''),
    Email: new UntypedFormControl(''),
    Bio: new UntypedFormControl(''),
    DateOfBirth: new UntypedFormControl(''),
    ConfirmPassword: new UntypedFormControl(''),
  });
picker: any;

  constructor(private _adminApiService: AdminApiService,
    private toastr: ToastrService,
    private router: Router,private _userService: UserService) { }

  ngOnInit(): void {
    this.checkValidation();
    if (!this.isAdminRole()) {
      this.router.navigate(['/']);
    }
  }

  get f() {
    return this.SignUpForm.controls;
  }

  onFormSubmit() {

    console.log('hello');
    this.SignUpForm.updateValueAndValidity();
    // if( != this.f.confirmPassword.value){
    //     this.f.ConfirmPassword.setValidators([
    //       Validators.required
    //     ]);
    //   }
    this.isValidFormSubmitted = false;
    if (this.SignUpForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", { closeButton: true });
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const requestPayload = this.prepareRequestModel();

    AppComponent.showLoader();


    this._adminApiService.AddUser(requestPayload)
      .subscribe(
        data => {
          this.toastr.success("SignUp Successfully", "Success", { closeButton: true });
          if (data.success && data.result) {
            this.f.UserId.setValue(data.result.UserId);
          }
          this.reset();
          this.router.navigate(['/signin']);
          AppComponent.hideLoader();

        },
        error => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
    );
  }



  prepareRequestModel() {
    const reequestModel = <any>{};

    if (this.f.UserId.value)
    reequestModel.UserId = this.f.UserId.value;
    reequestModel.FirstName = this.f.FirstName.value;
    reequestModel.LastName = this.f.LastName.value;
    reequestModel.Username = this.f.Username.value;
    reequestModel.Email = this.f.Email.value;
    reequestModel.Password = this.f.Password.value;
    reequestModel.PhoneNumber = this.f.PhoneNumber.value;
    reequestModel.Bio= this.f.Bio.value;
    reequestModel.DateOfBirth = this.f.DateOfBirth.value;
    reequestModel.ConfirmPassword = this.f.ConfirmPassword.value;

    // this.pas=reequestModel.Password;
    // this.cpas= reequestModel.ConfirmPassword;

    // if(this.pas != this.cpas){
    //   this.f.ConfirmPassword.setValidators([Validators.required]);
    // }

    return reequestModel;
  }

  checkValidation() {
    this.f.FirstName.setValidators([Validators.required]);
    this.f.LastName.setValidators([Validators.required]);
    this.f.Username.setValidators([Validators.required]);
    this.f.Password.setValidators([Validators.required,
     // Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$")
     Validators.pattern("^(?=.*[0-9])"
     + "(?=.*[a-z])(?=.*[A-Z])"
     + "(?=.*[@#$%^&+=])"
     + "(?=\\S+$).{8,20}$")
    ]);
    //this.f.Password.setValidators([Validators.required]);

    this.f.Email.setValidators([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
    this.f.PhoneNumber.setValidators([Validators.required,  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]);
    this.f.DateOfBirth.setValidators([Validators.required]);
    this.f.Bio.setValidators([Validators.required,Validators.minLength(10)]);
    this.f.ConfirmPassword.setValidators([Validators.required]);
  //   if(this.f.password.value != this.f.confirmPassword.value){
  //   this.f.ConfirmPassword.setValidators([
  //     Validators.required
  //   ]);
  // }


    //this.f.ConfirmPassword.setValidators([Validators.required]);
    //this.SignUpForm.setValidators(this.passwordMatchValidator);


  }
  reset() {

    this.f.UserId.setValue("");
    this.f.FirstName.setValue("");
    this.f.LastName.setValue("");
    this.f.Username.setValue("");
    this.f.Password.setValue("");
    this.f.Email.setValue("");
    this.f.Bio.setValue("");
    this.f.DateOfBirth.setValue("");
  }

  // passwordMatchValidator(group: FormGroup) {
  //   const password = group.get('Password').value;
  //   const confirmPassword = group.get('ConfirmPassword').value;

  //   return password === confirmPassword ? null : { passwordMismatch: true };
  // }

  // passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  //   const password = control.get('Password').value;
  //   const confirmPassword = control.get('ConfirmPassword').value;
  //   if (password !== confirmPassword) {
  //     return { passwordMismatch: true };
  //   } else {
  //     return null;
  //   }
  // }

  confirmpassword(){
    if(this.f.password.value != this.f.confirmPassword.value){
      console.log('password not match');
    }
  }
  isAdminRole() {
    return this._userService.GetUserPermission("admin");
  }

}
