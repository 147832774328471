import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { AppComponent } from 'src/app/app.component';
import { CGRZChecklistDatabase } from 'src/app/models/CGRZCheckListDatabase';
import { CGItemFlatNode, CGItemNode, ComponentDetail } from 'src/app/models/treeview';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent implements OnInit {

  reportIncidentForm: FormGroup;
  selectfile:any;
  isValidFormSubmitted = null;
  //checklistSelection = new SelectionModel<CGItemFlatNode>(true);
  affectedComponents = [];
  cgregionZones = [];
  statusOptions: Array<Object> = [{ name: 'Select', value: '' }];
  typeOptions: Array<Object> = [{ name: 'Select', value: '' }];
  sourceOptions: Array<Object> = [{ name: 'Select', value: '' }];
  subTypeOptions: Array<Object> = [{ name: 'Select', value: '' }];
  //isNew = true;

  ReportIncidentForm = new UntypedFormGroup({
    ReportId: new UntypedFormControl(''),
    Name:new UntypedFormControl(''),
    Email:new UntypedFormControl(''),
    Subject: new UntypedFormControl(''),
    Status: new UntypedFormControl(''),
    Product: new UntypedFormControl(''),
    WebsiteUrl: new UntypedFormControl(''),
    Attachment: new UntypedFormControl(''),
    Description: new UntypedFormControl(''),
  });

  constructor(private _adminApiService: AdminApiService,
    private toastr: ToastrService,
    private router: Router,
    private cgrzdb: CGRZChecklistDatabase,
    private cdRef: ChangeDetectorRef) {
      this.treeFlattener = new MatTreeFlattener(
        this.transformer,
        this.getLevel,
        this.isExpandable,
        this.getChildren,
      );
      this.treeControl = new FlatTreeControl<CGItemFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

      this.cgrzdb.dataChange.subscribe(data => {
        this.dataSource.data = data;
      });
     }

  ngOnInit(): void {
    this.checkValidation();
   // this.getEditableComponents();
    this.getIncidentsMetadata();
    this.getAllComponentRegionsAndZones();
  }

  get f() {
    return this.ReportIncidentForm.controls;
  }

  onFormSubmit() {
    this.isValidFormSubmitted = false;
    if (this.ReportIncidentForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", { closeButton: true });
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const formData = new FormData();

    const requestPayload = this.prepareRequestModel();

    console.log(requestPayload);

    formData.append('Name',requestPayload.Name);
    formData.append('Email',requestPayload.Email);
    formData.append('Subject',requestPayload.Subject);
    formData.append('Product',requestPayload.Product);
    formData.append('WebsiteUrl', requestPayload.WebsiteUrl);
    formData.append('Description',requestPayload.Description);
    formData.append('Status',requestPayload.Status);
    formData.append('Attachment',this.selectfile);

    AppComponent.showLoader();

    console.log(formData);

    this._adminApiService.ReportIncident(formData)
      .subscribe(
        data => {
          console.log(formData);
          this.toastr.success("Incident submitted Successfully", "Success", { closeButton: true });
          if (data.success && data.result) {
            this.f.ReportId.setValue(data.result.ReportId);
          }
          this.reset();
          this.router.navigate(['']);
          AppComponent.hideLoader();

        },
        error => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
    );
  }


  onFileSelected(event:any):void{
    this.selectfile = event.target.files[0];
  }
  getIncidentsMetadata() {
    this._adminApiService.GetIncidentMetadata()
      .subscribe(
        data => {
          if (data && data.success) {

            if (data.result && data.result.incidentTypeList && data.result.incidentTypeList.length > 0) {

              data.result.incidentTypeList.forEach((item) => {
                  this.typeOptions.push({ name: item, value: item });
              });
            }

            if (data.result && data.result.incidentStatusList && data.result.incidentStatusList.length > 0) {
              this.statusOptions = [{ name: 'Select', value: '' }];

              data.result.incidentStatusList.forEach((item) => {
                this.statusOptions.push({ name: item, value: item });
              });
            }

            if (data.result && data.result.sourceTypesList && data.result.sourceTypesList.length > 0) {
              this.sourceOptions = [{ name: 'Select', value: '' }];

              data.result.sourceTypesList.forEach((item) => {
                this.sourceOptions.push({ name: item, value: item });
              });
            }

            if (data.result && data.result.incidentSubTypesList && data.result.incidentSubTypesList.length > 0) {

              data.result.incidentSubTypesList.forEach((item) => {
                this.subTypeOptions.push({ name: item, value: item });
              });
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  // getSeletedComponents() {
  //   let itemNode: ComponentDetail[] = [];
  //   this.checklistSelection.selected.forEach((item) => {

  //     let component = itemNode?.filter(x => x.componentId == item.item.componentId
  //       && x.regionId == item.item.regionId && x.zoneId == item.item.zoneId);

  //     if (item.level == 2 && (component == null || component?.length <= 0)) {
  //       let componentDetail = new ComponentDetail();
  //       componentDetail.componentId = item.item.componentId;
  //       componentDetail.componentName = item.item.componentName;
  //       componentDetail.regionId = item.item.regionId;
  //       componentDetail.regionName = item.item.regionName;
  //       componentDetail.zoneId = item.item.zoneId;
  //       componentDetail.zoneName = item.item.zoneName;
  //       itemNode.push(componentDetail);
  //     }
  //   });
  //   return itemNode;
  // }

  prepareRequestModel() {
    const reequestModel = <any>{};

    if (this.f.ReportId.value)
    reequestModel.ReportId = this.f.ReportId.value;
    reequestModel.Name = this.f.Name.value;
    reequestModel.Email = this.f.Email.value;
    reequestModel.Subject = this.f.Subject.value;
    debugger;
    reequestModel.Product = this.getSeletedComponents();
    reequestModel.WebsiteUrl = this.f.WebsiteUrl.value;
    reequestModel.Description = this.f.Description.value;
    reequestModel.Status = this.f.Status.value;
    reequestModel.Attachment = this.f.Attachment.value;

    return reequestModel;
  }

  checkValidation() {
    this.f.Name.setValidators([Validators.required]);
    this.f.Email.setValidators([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
    this.f.Subject.setValidators([Validators.required]);
   // this.f.Product.setValidators([Validators.required]);
    this.f.Description.setValidators([Validators.required,Validators.maxLength(500)]);
    //this.f.Password.setValidators([Validators.required]);
    this.f.WebsiteUrl.setValidators([Validators.required,Validators.pattern('^(https?://).*$')]);
    this.f.Status.setValidators([Validators.required]);
    //this.f.Attachment.setValidators([Validators.required]);

  }
  cancel() {
    //this.location.back();
    window.history.back();
  }
  reset() {

    this.f.ReportId.setValue("");
    this.f.Name.setValue("");
    this.f.Email.setValue("");
    this.f.Subject.setValue("");
    this.f.Product.setValue("");
    this.f.WebsiteUrl.setValue("");
    this.f.Description.setValue("");
    this.f.Status.setValue("");
    this.f.Attachment.setValue("");

  }



  getSeletedComponents() {
    debugger;
    let itemNode = [];
    this.checklistSelection.selected.forEach((item) => {

      let component = itemNode?.filter(x => x.componentId == item.item.componentId
        && x.regionId == item.item.regionId && x.zoneId == item.item.zoneId);

      if (item.level == 1 && (component == null || component?.length <= 0)) {
         itemNode.push(item.item.componentId);
      }
    });
    console.log(itemNode);
    return itemNode;
  }

  getAllComponentRegionsAndZones() {
    this._adminApiService.GetAllComponentGroupsWithRegionAndZone()
      .subscribe(
        data => {
          if (data && data.success && data.result) {

            if (data.result && data.result.length > 0) {
              this.cgregionZones = data.result;
              this.cgrzdb.initialize(this.cgregionZones);
              //this.treeControl.expandAll();

              this.cdRef.detectChanges();

            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }



  flatNodeMap = new Map<CGItemFlatNode, CGItemNode>();
  nestedNodeMap = new Map<CGItemNode, CGItemFlatNode>();
  selectedParent: CGItemFlatNode | null = null;
  treeControl: FlatTreeControl<CGItemFlatNode>;
  treeFlattener: MatTreeFlattener<CGItemNode, CGItemFlatNode>;
  dataSource: MatTreeFlatDataSource<CGItemNode, CGItemFlatNode>;
  checklistSelection = new SelectionModel<CGItemFlatNode>(true);

  getLevel = (node: CGItemFlatNode) => node.level;
  isExpandable = (node: CGItemFlatNode) => node.expandable;
  getChildren = (node: CGItemNode): CGItemNode[] => node.children;
  hasChild = (_: number, _nodeData: CGItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: CGItemFlatNode) => !_nodeData.item;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: CGItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new CGItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: CGItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: CGItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: CGItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: CGItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: CGItemFlatNode): void {
    let parent: CGItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: CGItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
    this.getSeletedComponents();
  }

  /* Get the parent node of a node */
  getParentNode(node: CGItemFlatNode): CGItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  setComponentSelection(components) {
    debugger;
    if (components) {
      components.forEach((component) => {
        this.treeControl.dataNodes.forEach((c) => {
          if (c.item && c.item.zoneId && c.item.zoneId == component.zoneId
            && c.item.regionId == component.regionId && c.item.componentId == component.componentId) {
            this.checklistSelection.select(c);

            if (c.level == 2) {
              let parentNode = this.getParentNode(c);
              this.treeControl.expand(this.getParentNode(parentNode));
              this.treeControl.expand(parentNode)
            }
          }
        });
      });
    }

  }
}
