import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sharedService } from '../../api/sharedService';
import { Globals } from '../../global';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { CustomRoutesServiceService } from 'src/app/api/CustomRoutesService.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

  currentYear;
  homePageURL;
  linkedinlink: any;
  twitterlink: any;
  SlackLink: any;
  phone: any;
  footer: any;
  footerlink: any;
  GlobalAnnouncement:any = null;
  shouldShowAlert: boolean = true;

  constructor(
    public router: Router,
    private sharedService: sharedService,
    private cookieService: CookieService,
    private customRouterService: CustomRoutesServiceService,
    private adminApiService: AdminApiService
  ) {
    this.currentYear = new Date().getUTCFullYear();
    this.homePageURL = Globals.homePageURL;
  }

  ngOnInit() {
    this.adminApiService.GetSettings().subscribe((data) => {
      const setting = data.result[0];
      console.log(setting);
      if (setting) {
        this.linkedinlink = setting.linkedinUrl;
        this.twitterlink = setting.twitterId;
        this.footerlink = setting.footerLink;
        this.phone = setting.phoneNo;
        this.SlackLink = setting.slackLink;
        this.footer = setting.footerDescription;
        this.GlobalAnnouncement = setting.globalAnnouncement;
        if (setting.globalAnnouncement == null || setting.globalAnnouncement == 'null') {
          this.shouldShowAlert = false;
        }
      }
    });
    // Check if the cookie is set to hide the alert
    const hideAlertCookie = this.cookieService.get('GlobalAnnouncement');
    console.log(hideAlertCookie)
    if (hideAlertCookie == 'true') {
      this.shouldShowAlert = false;
    }
    else{
      this.shouldShowAlert = true;
    }
   
  }

  setCookie() {
    // Set a cookie to hide the alert for the next 24 hours
    const expirationTime = 24 * 60 * 60; // 24 hours in seconds
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + expirationTime * 1000);
    this.cookieService.set('GlobalAnnouncement', 'true', expirationDate);
  }
}
