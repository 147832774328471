import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/api/user.service';
import { CookieService } from 'ngx-cookie-service';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';


@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  UserData:any;
  userRolesFromDatabase: any[] = [
    { name: 'ReadWriteOnly' },
    { name: 'ReadOnly' },
    { name: 'Admin' },
  ];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  inputText: string = '';
  editedLabel: string = '';
  editIndex: number = -1;
  labels: string[] = [];
  selectedRole: string = 'ReadOnly';
  isInviteUserModel:boolean = true;

  Inviteform!:FormGroup;
  isValidFormSubmitted = null;
  @ViewChild('exampleModal') exampleModal!: ElementRef;

  constructor(private userApi: UserService, private toastr: ToastrService,private router: Router,private _userService: UserService,private cookieService: CookieService) {
  this.Inviteform = new UntypedFormGroup({
    Email: new UntypedFormControl(''),
    rols: new UntypedFormControl(''),
  });
  }
  Loginuser:any;
  ngOnInit(): void {
    if (!this.isAdminRole()) {
      this.router.navigate(['/admin/events']);
    }
    this.getAllUsers();
    this.GetSearch();
  }

  getCookieValue(cookieName: string): string {
    return this.cookieService.get(cookieName);
  }


  HideLoginUser(){
    const myCookieValue = this.getCookieValue('loggedInUser');
    const parsedCookie = JSON.parse(myCookieValue);
    const userName = parsedCookie.username;
    this.Loginuser = userName;
  }


  GetSearch(){
    this.dtOptions = {
      lengthMenu: [
        [10, 25, 50, 100],
        [10, 25, 50, 100]
      ],
      ordering: true,
      serverSide: true,
      processing: false,
      destroy: true,
      columnDefs: [
        {
          "targets": [0, 2, 3, 4, 5, 6, 7,8],
          "orderable": false
        },{
        "name":"user",
        "targets": 1,
        "orderable": true
      }],
      ajax: (dataTablesParameters: any, callback) => {
        this.userApi.GetAllSearchUser(dataTablesParameters)
          .subscribe(
            data => {
              if (data && data.success && data.result) {
                var toteluser = data.result;
                this.UserData = data.result.list;
                callback({
                  recordsTotal: toteluser.recordsTotal,
                  recordsFiltered: toteluser.filteredCount,
                  data: [],
                });
                //this.reload();
              }
            },
            error => {
              console.log(error);
              callback({
                recordsTotal: this.UserData?.totalCount,
                recordsFiltered: this.UserData?.recordsTotal,
                data: []
              });
            }
          );
      },
    };

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getAllUsers(): void {
    this.userApi.GetAlluser().subscribe(
      (data: any) => {
        if (data.success && data.result) {
          this.UserData = data.result;
          console.log(data.result)
          this.HideLoginUser();
        } else {
          this.handleError('Failed to fetch user data');
        }
      },
      error => {
        this.handleError('An error occurred while fetching user data: ' + error);
      }
    );
  }

  toggleActive(user: any): void {
    const updatedUser = { ...user, isActive: user.isActive };
    this.updateUser(updatedUser, 'isActive');
  }

  togglerole(user: any): void {
    this.updateUser(user, 'role');
  }

  private updateUser(user: any, updateType: string): void {
    this.userApi.UpdateroleAndisActiveuser(user).subscribe(
      (data: any) => {
        if (data) {
          this.handleSuccess(`User ${updateType} updated successfully`);
        } else {
          this.handleError(`Failed to update ${updateType}`);
          // Revert the changes if the update fails
          if (updateType === 'isActive') {
            user.isActive = !user.isActive;
          }
        }
      },
      error => {
        this.handleError('An error occurred during update: ' + error);
        // Revert the changes if the update fails
        if (updateType === 'isActive') {
          user.isActive = !user.isActive;
        }
      }
    );
  }

  private handleSuccess(message: string): void {
    this.toastr.success(message, 'Success', { closeButton: true });
  }


  isAdminRole() {
    return this._userService.GetUserPermission("admin");
  }
  handleError(errorMessage: string): void {
    // Handle errors here
    console.error(errorMessage);
  }


  get f() {
    return this.Inviteform.controls;
  }
  checkValidation() {
    this.f.Email.setValidators([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
  }

  isSaveDisabled(): boolean {
    return this.labels.length === 0;
  }

  addLabel() {
    if (this.inputText.trim() !== '') {
      const emailControl = new FormControl(this.inputText, Validators.email);
        if (emailControl.valid){
          this.Inviteform.updateValueAndValidity();
          this.isValidFormSubmitted = false;
          this.labels.push(this.inputText);
          this.inputText = '';
        }
    }

  }

  removeLabel(index: number) {
    this.labels.splice(index, 1);
  }

  editLabel(index: number) {
    this.editedLabel = this.labels[index];
    this.editIndex = index;
  }

  saveLabel(index: number) {
    if (this.editedLabel.trim() !== '') {
      const emailControl = new FormControl(this.editedLabel, Validators.email);
      if(emailControl.valid){
        this.labels[index] = this.editedLabel;
        this.editIndex = -1;
        this.editedLabel = '';
      }
    }
  }

  cancelEdit() {
    this.editIndex = -1;
    this.editedLabel = '';
  }

  save() {
    const userData = {
      emails: this.labels,
      role: this.selectedRole
    };

    AppComponent.showLoader();

    this._userService.saveInviteUser(userData).subscribe(
      data => {
        console.log(data);
        this.toastr.success("User Invited Successfully", "Success", { closeButton: true });
        this.labels = [];
        this.selectedRole = 'ReadOnly';
        this.isInviteUserModel = false;
        this.getAllUsers();
        AppComponent.hideLoader();
      },
      error => {
        if (error && error.error && error.error.message){

          this.toastr.error(error.error.message, "Error", { closeButton: true });
          this.labels = [];
          this.selectedRole = 'ReadOnly';
          this.isInviteUserModel = false;
          this.getAllUsers();
        }
        else
          this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });
          AppComponent.hideLoader();
      }
    );
  }


}
