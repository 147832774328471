import { Inject } from "@angular/core";
import { environment } from "../environments/environment";
export class Globals {
  static homePageURL: string = "/";
  static apiURL: string ="https://devstatusapi.itechops.com/api/";
  // static apiURL: string ="https://statusapi.itechops.com";
  public static timeZone = "(UTC)";
  public static commonRegionName = "Non-Regional";
  public static recaptchaSiteKey? = '6Let9A8fAAAAABlcqOjacbT_qEsLBfgglZWpyZtp';
  static homeDtOptions: DataTables.Settings = {
    lengthMenu: [
      [10, 25, 50, 100, -1],
      [10, 25, 50, 100, "All"]
    ],
    ordering: false,
    paging: false,
    dom: 'lrt',
    columns: [
      { "visible": true },
    ],
  };
}
