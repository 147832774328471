import { Injectable } from '@angular/core';
import { StatusApiService } from './statusapi.service';
import { AdminApiService } from './adminapi.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { sharedService } from './sharedService';
import { ToastrService } from 'ngx-toastr';
import { user } from '../models/UserModel';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private _statusApiService: StatusApiService,
    private adminApiService: AdminApiService,
    private userService:UserService,
    public router: Router,
    private _sharedService: sharedService,
    private toastr: ToastrService) {
    }

  //private isLoggedIn = false;

  // login(userModel: user) {
  //   return this.adminApiService.login(userModel);
  // }

  // login(userModel: user) {
  //   return this.adminApiService.login(userModel).pipe(
  //     tap(response => {
  //       if (response && response.result.token) {
  //         //this.setLoggedInStatus(true);
  //         localStorage.setItem('token', response.result.token); // Store token in local storage
  //         this.router.navigate(['/signin']);
  //       }
  //     })
  //   );
  // }

  login(userModel: user) {
    return this.adminApiService.login(userModel).pipe(
      tap(response => {
        if (response && response.result.token) {
          const token = response.result.token;
          const expirationTime = new Date().getTime() + 3600 * 1000; // Set expiration to 5 minutes (300 seconds)
          localStorage.setItem('token', token);
          localStorage.setItem('tokenExpiration', expirationTime.toString());
          this.router.navigate(['/signin']);
        }
      })
    );
  }

  getToken() {
    return localStorage.getItem('token');
  }
  // get isLoggedIn(): boolean {
  //   let authToken = localStorage.getItem('token');
  //   return authToken !== null ? true : false;
  // }

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (authToken && tokenExpiration) {
      const currentTime = new Date().getTime();
      const expirationTime = parseInt(tokenExpiration, 10);

      if (currentTime < expirationTime) {
        return true; // Token is not expired
      } else {
        // Token has expired, perform automatic logout
        this.doLogout();
        return false;
      }
    }
    return false; // No token or tokenExpiration found
  }

  doLogout() {
    let removeToken = localStorage.removeItem('token');
    if (removeToken == null) {
      this.router.navigate(['/signin']);
    }
  }



  // logout() {
  //   // Logic to perform logout
  //   this.isLoggedIn = false;
  // }

  // setLoggedInStatus(status: boolean) {
  //   this.isLoggedIn = status;
  //   // Update local storage value
  //   localStorage.setItem('isAuthenticated', status.toString());
  //   }

  // get isAuthenticated() {
  //   return this.isLoggedIn;
  // }


}

