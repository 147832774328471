import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusApiService } from '../../../api/statusapi.service';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../global';
import { Incident } from '../../../models/StatusPageModel';

@Component({
  selector: 'app-event-detail-dialog',
  templateUrl: './event-detail-dialog.component.html',
  styleUrls: ['./event-detail-dialog.component.css']
})
export class EventDetailDialogComponent implements OnInit {
  eventId: string = '';
  event: Incident;
  timeZone;

  constructor(private dialogRef: MatDialogRef<EventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _statusApiService: StatusApiService) {
    this.eventId = data;
    this.timeZone = Globals.timeZone;

    if (this.eventId) {
      this.getEventHistory();
    }
    else {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
  }

  getEventHistory() {
    AppComponent.showLoader();
    this._statusApiService.GetIncidentHistory(this.eventId)
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.event = data.result;
          }
          else {
            this.dialogRef.close();
          }
          AppComponent.hideLoader();
        },
        error => {
          console.log(error);
          AppComponent.hideLoader();
          this.dialogRef.close();
        }
      );
  }

}
