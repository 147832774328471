import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StatusApiService } from '../../api/statusapi.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { Home2Component } from './home2/home.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { environment } from '../../../environments/environment';
import { CGRZChecklistDatabase } from '../../models/CGRZCheckListDatabase';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { Home3Component } from './home3/home3.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', component: Home2Component },
      { path: 'old', component: HomeComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'event-detail/:id', component: EventDetailComponent },
      { path: 'event-detail', component: EventDetailComponent },
      { path: 'subscription/:id', component: SubscriptionComponent },
    //  { path: 'home3', component: Home3Component }
    ]
  }
];

@NgModule({
  declarations: [
    MainComponent,
    NavMenuComponent,
    HomeComponent,
    Home2Component,
    //Home3Component,
    HistoryComponent,
    EventDetailComponent,
    SubscriptionComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DataTablesModule,
    NgxTippyModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
  ],
  providers: [
    StatusApiService,
    CGRZChecklistDatabase
  ],
  bootstrap: [MainComponent]
})
export class MainModule { }
