import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from 'src/app/api/adminapi.service';

@Component({
  selector: 'app-feature-requestmodals',
  templateUrl: './feature-requestmodals.component.html',
  styleUrls: ['./feature-requestmodals.component.css']
})
export class FeatureRequestmodalsComponent  {
  form: FormGroup;
  constructor(
    private adminapi :AdminApiService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.fb.group({
      Title: ['', Validators.required],
      Description: ['', Validators.required]
    });
  }


  submitForm() {
    if (this.form.valid) {
        const formData = this.form.value;
        this.adminapi.Addfeaturerequestmodals(formData).subscribe(
            (response) => {
              this.toastr.success("Feature Request Send Successfully", "Success", {
                closeButton: true,
              });
                this.closeDialog();
            },
            (error) => {
              if (error && error.error && error.error.message)
              this.toastr.error(error.error.message, "Error", {
                closeButton: true,
              });
            else
              this.toastr.error(
                "Something Went Wrong. Please contact Admin",
                "Error",
                { closeButton: true }
              );
            }
        );
    }
}

  
  closeDialog(): void {
    this.dialog.closeAll();
  }


}
