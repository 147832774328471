import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  eventId: string = "";
  eventType: string = "";
  eventTitle: string = "";
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.eventId = data.eventId || this.eventId;
      this.eventType = data.eventType || this.eventType;
      this.eventTitle = data.eventTitle || this.eventTitle;


      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(opt): void {
    this.dialogRef.close({
      eventId: this.eventId,
      isDelete: opt,
      eventType: this.eventType,
      eventTitle: this.eventTitle
    });
  }

  ngOnInit(): void {
  }

}
