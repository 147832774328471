import { Options } from "@angular-slider/ngx-slider";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AdminApiService } from "src/app/api/adminapi.service";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  templateUrl: "./uptime-add.component.html",
  styleUrls: ["./uptime-add.component.css"],
})
export class uptimeAddComponent implements OnInit {
  IntervalsliderValue:number = 1;
  IntervalminValue:number = 5;
  IntervalmaxValue:number = 60;
  isInputEnabled: boolean = true;

  TimeoutsliderValue: number = 1;
  TimeoutminValue: number = 1;
  TimeoutmaxValue: number = 10;
  Type = "";
  titel: any;
  isValidFormSubmitted = null;
  Intervalvalue: number = 5;
  optionsInterval: Options = {
    floor: 1,
    ceil: 60,
  };
  HTTPResponse : boolean = true;
  HTTPResponseCodeEqual :string='ResponseCodeGroup1';
  selectedHTTPMethod: string='';
  Timeoutvalue: number = 1;
  optionsTimeout: Options = {
    floor: 1,
    ceil: 10,
  };
  ResponseCodeEqual: any = "=";
  enableInput() {
    this.isInputEnabled = true;
  }

  disableInput() {
    this.isInputEnabled = false;
  }

  constructor(
    private _adminApiService: AdminApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    ) {


  }
  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get("id");
    this.f.uptimeTestId.setValue(id);
    this.checkValidation();
    if (id) {
      this.titel = "Edit";
      this.getEditData();
    } else {
      this.titel = "Add";
    }
    this.Type = "";
    this.f.ResponseCodeEqual.setValue(200);
  }
  show() {
    this.checkValidation();
  }
  settingsForm = new UntypedFormGroup({
    uptimeTestId: new UntypedFormControl(""),
    Type: new UntypedFormControl(""),
    Name: new UntypedFormControl(""),
    Url: new UntypedFormControl(""),
    Ip: new UntypedFormControl(""),
    HTTP_Method: new UntypedFormControl(""),
    Interval: new UntypedFormControl(""),
    Timeout: new UntypedFormControl(""),
    Custom_Headers: new UntypedFormControl(""),
    Domain: new UntypedFormControl(""),
    SSL: new UntypedFormControl(""),
    HTTPResponses: new UntypedFormControl(""),
    ResponseCodeEqual: new UntypedFormControl(""),
    ResponseCode: new UntypedFormControl("200"),
    Body: new UntypedFormControl(""),
  });
  get f() {
    return this.settingsForm.controls;
  }
  prepareRequestModel() {
    const reequestModel = <any>{};
    if (this.f.uptimeTestId.value) {
      reequestModel.uptimeTestId = this.f.uptimeTestId.value;
    }
    reequestModel.type = this.f.Type.value;
    reequestModel.name = this.f.Name.value;
    if (this.Type == "HTTP") {
      reequestModel.url = this.f.Url.value;
    } else {
      let ipAddress = this.f.Ip.value;
      ipAddress = ipAddress.replace(/^(https?:\/\/)./, "");
      reequestModel.url = ipAddress;
    }
    reequestModel.httP_Method = this.f.HTTP_Method.value;
    reequestModel.interval = this.f.Interval.value;
    reequestModel.timeout = this.f.Timeout.value;
    reequestModel.custom_Headers = this.f.Custom_Headers.value;
    if (this.f.Domain.value == "") {
      reequestModel.domain_expiry_reminders = false;
    } else {
      reequestModel.domain_expiry_reminders = this.f.Domain.value;
    }
    if (this.f.SSL.value == "") {
      reequestModel.enable_SSL_Expiry_Reminder = false;
    } else {
      reequestModel.enable_SSL_Expiry_Reminder = this.f.SSL.value;
    }
    reequestModel.uptimeAction = "Start";
    reequestModel.HTTPResponses = this.f.HTTPResponses.value;
    reequestModel.ResponseCodeequals = this.f.ResponseCodeEqual.value;
    reequestModel.ResponseCode = this.f.ResponseCode.value;
    if (
      this.selectedHTTPMethod === "PUT" ||
      this.selectedHTTPMethod === "POST"
    ) {
      reequestModel.Body = this.f.Body.value;
    }
    return reequestModel;
  }
  onFormSubmit() {
    this.isValidFormSubmitted = false;

    if (this.settingsForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", {
        closeButton: true,
      });
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;
    const reequestModel = this.prepareRequestModel();
    AppComponent.showLoader();

    console.log(reequestModel);

    if (!this.f.uptimeTestId.value) {
      this._adminApiService.AddUptime(reequestModel).subscribe(
        (data) => {
          this.toastr.success("Uptime save Successfully", "Success", {
            closeButton: true,
          });
          AppComponent.hideLoader();
          console.log(data);
          this.router.navigate(["/admin/uptime"]);
        },
        (error) => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", {
              closeButton: true,
            });
          else
            this.toastr.error(
              "Something Went Wrong. Please contact Admin",
              "Error",
              { closeButton: true }
            );
          AppComponent.hideLoader();
        }
      );
    } else {
      this._adminApiService.UptimeUptime(reequestModel).subscribe(
        (data) => {
          this.toastr.success("Uptime Update Successfully", "Success", {
            closeButton: true,
          });
          AppComponent.hideLoader();
          console.log(data);
          this.router.navigate(["/admin/uptime"]);
        },
        (error) => {
          if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", {
              closeButton: true,
            });
          else
            this.toastr.error(
              "Something Went Wrong. Please contact Admin",
              "Error",
              { closeButton: true }
            );
          AppComponent.hideLoader();
        }
      );
    }
  }
  checkValidation() {
    this.f.Type.setValidators([Validators.required]);
    this.f.Name.setValidators([Validators.required]);
    if (this.Type == "HTTP") {
      this.Type = "HTTP";
      this.f.Url.setValidators([
        Validators.required,
        Validators.pattern("^(https?://).*$"),
      ]);
    } else {
      if (this.Type == "PING") {
        this.Type = "PING";
        this.f.Ip.setValidators([Validators.required, this.ipOrUrlValidator()]);
      }
    }

      // Revalidate the form after updating validators
      this.settingsForm.updateValueAndValidity();
      if(this.Type=="HTTP"){
        this.f.HTTP_Method.setValidators([Validators.required]);
      }
      this.f.Interval.setValidators([
        Validators.pattern(/^(?:[1-9]|[1-5][0-9]|60)$/),
        Validators.required
      ]);
      this.f.Timeout.setValidators([
        Validators.pattern(/^(?:[1-9]|10)$/),
        Validators.required
      ]);
  }

  getEditData(){
    let id = this.route.snapshot.paramMap.get('id');
    this._adminApiService.getUptimebyid(id).subscribe((data) => {
      if (data.success && data.result) {
        this.f.uptimeTestId.setValue(data.result.uptimeTestId)
        this.f.Name.setValue(data.result.name)
        this.f.Type.setValue(data.result.type)
        if (data.result.type == 'HTTP') {
          this.f.Url.setValue(data.result.url)
        }
        else{
          this.f.Ip.setValue(data.result.url)
        }
        this.f.HTTP_Method.setValue(data.result.httP_Method)
        this.f.Interval.setValue(data.result.interval)
        this.f.Timeout.setValue(data.result.timeout)
        this.f.Custom_Headers.setValue(data.result.custom_Headers)
        this.f.SSL.setValue(data.result.enable_SSL_Expiry_Reminder)
        this.f.Domain.setValue(data.result.domain_expiry_reminders)
        this.f.HTTPResponses.setValue(data.result.httpResponse)
        this.f.ResponseCodeEqual.setValue(data.result.responseCodeequals)
        this.f.ResponseCode.setValue(data.result.responseCode)
        this.f.Body.setValue(data.result.body)
      }
      AppComponent.hideLoader();
    }),
    error => {
      if (error && error.error && error.error.message)
        this.toastr.error(error.error.message, "Error", { closeButton: true });
      else
        this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });
      AppComponent.hideLoader();
    }
  }
  ipOrUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value) {
        const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        if (ipRegex.test(value)) {
          return null;
        }

        const urlRegex = /^(https?:\/\/).*/;
        if (urlRegex.test(value)) {
          return null;
        }

        return { invalidIpOrUrl: true };
      }
      return null;
    };
  }



  reset() {
    this.f.Name.setValue("");
    this.f.Type.setValue("");
    this.f.Url.setValue("");
    this.f.Ip.setValue("");
    this.f.HTTP_Method.setValue("");
    this.f.Interval.setValue("");
    this.f.Timeout.setValue("");
    this.f.Custom_Headers.setValue("");
    this.f.SSL.setValue(false);
    this.f.Domain.setValue(false);
    this.f.Body.setValue("");
  }
}
