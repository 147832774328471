import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AdminApiService } from 'src/app/api/adminapi.service';
import { CookieService } from 'ngx-cookie-service';
import { sharedService } from '../../../api/sharedService';
import { StatusApiService } from '../../../api/statusapi.service';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../global';
import { EventDetailDialogComponent } from '../event-detail-dialog/event-detail-dialog.component';
import { Incident, StatusPageModel } from '../../../models/StatusPageModel';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/api/user.service';
import { environment } from 'src/environments/environment';
declare var grecaptcha: any;
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent implements OnInit {
  @ViewChild('recaptchaElement') recaptchaElement: ElementRef;

  displayNotificationBar = false;
  logoUrl: string = ''; // Initialize to avoid runtime errors
  statusData: StatusPageModel;
  incidents: Incident[] = [];
  maintenances: Incident[] = [];
  activeAnnouncements: Incident[] = [];
  totalNotifications = 0;
  isNotificationOpened = false;
  timeZone: any;
  isValidFormSubmitted: boolean | null = null;
  subscribeForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
  });
  title: string = ''; // Declare with a specific type

  constructor(
    private _statusApiService: StatusApiService,
    private adminApiService: AdminApiService,
    private toastr: ToastrService,
    public router: Router,
    private dialog: MatDialog,
    private _sharedService: sharedService,
    private cookieService: CookieService,
    private http: HttpClient,
    private user:UserService,
    private fb: FormBuilder
  ) {}
  countryCodes = {
    "Afghanistan": "+93",
    "Albania": "+355",
  };
  smsForm: FormGroup;
  countries: { name: string, code: string }[] = [];
  IsActiveSlack
  IsActiveSMS
  ngOnInit() {
     this.IsActiveSlack = environment.IsActiveSlack;
     this.IsActiveSMS = environment.IsActiveSMS;

    this.addRecaptchaScript();
    this.getCookie();
    this.timeZone = Globals.timeZone;
    this.getComponentStatus();
    this.getActiveAnnouncments();
    this.adminApiService.GetSettings().subscribe(
      (data) => {
        console.log(data);
        const details = data.result[0];
        this.title = details?.title;
        this.logoUrl = details?.logoLink;
      },
      (error) => {
        console.error('Error fetching settings:', error);
        this.toastr.error('Failed to fetch settings. Please try again later.');
      }
    );
    for (const country in this.countryCodes) {
      this.countries.push({ name: country, code: this.countryCodes[country] });
    }
    this.smsForm = this.fb.group({
      countryCode: ['+91', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.maxLength(10)]]
    });
  }
  onSubmit() {
    if (this.smsForm.valid) {
      const countryCode = this.smsForm.get('countryCode').value;
      const phoneNumber = this.smsForm.get('phoneNumber').value;
      const fullPhoneNumber = countryCode + phoneNumber;
  
      this.http.post('https://devstatusapi.itechops.com/api/Subscriber/SMS', { SubscriptionValue: fullPhoneNumber })
        .subscribe((res:any) => {
          this.toastr.success(res.result, "Success", {
            closeButton: true,
          });
        }, (error) => {
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        });
    } else {
      console.error('Form validation failed');
    }
  }
  
  getCookie(): void {
    const value = this.cookieService.get('iSetting');
    const cookieObject = JSON.parse(value);
    this.title = cookieObject.title;
    this.logoUrl = cookieObject.logoLink;
  }

  get f() {
    return this.subscribeForm.controls;
  }
  onFormSubmit(recaptchaResponse: string) {
    console.log('Form submitted with email:', this.subscribeForm.value.email);
    console.log('reCAPTCHA response:', recaptchaResponse);
    if (this.subscribeForm.invalid) {
      this.toastr.error('Please provide a valid email.');
      return;
    }

    const requestPayload = {
      subscriptionValue: this.f.email.value,
      subscriberType: 'email',
    };

    AppComponent.showLoader();
    this._statusApiService.AddUpdateSubscriber(requestPayload).subscribe(
      (data) => {
        if (data.success && data.result) {
          window.location.href = '/subscription/' + data.result + '?newsubscribe=true';
        }
        AppComponent.hideLoader();
      },
      (error) => {
        if (error && error.error && error.error.message && error.error?.result)
          this.toastr.error(error.error?.result[0], 'Error', { closeButton: true });
        else if (error && error.error && error.error.message)
          this.toastr.error(error.error.message, 'Error', { closeButton: true });
        else this.toastr.error('Something Went Wrong. Please contact Admin', 'Error', { closeButton: true });

        AppComponent.hideLoader();
      }
    );
  }
  validate(event){
    event.preventDefault();
    this.isValidFormSubmitted = true;

    if (this.subscribeForm.invalid) {
      return;
    }

    try {
      grecaptcha.execute();
    } catch (error) {
      console.error('Error with reCAPTCHA:', error);
      this.toastr.error('Captcha verification failed. Please try again later.');
    }
  }

  renderReCaptcha() {
      grecaptcha.render(this.recaptchaElement.nativeElement, {
        sitekey: Globals.recaptchaSiteKey,
        callback: (response) => {
          console.log(response);
          this.onFormSubmit(response);
          grecaptcha.reset();
        },
        size: 'invisible'
      });
  }


  addRecaptchaScript() {
    const self = this;

    window['grecaptchaCallback'] = () => {
      self.renderReCaptcha();
    };
    if (window && window['grecaptcha']) {
      window['grecaptchaCallback']();
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }


  toggleNotificationBar() {
    this.isNotificationOpened = true;
    this.displayNotificationBar = !this.displayNotificationBar;
  }
  getComponentStatus() {
    this._statusApiService.GetComponentsStatus().subscribe(
      (data) => {
        if (
          data &&
          data.success &&
          data.result &&
          data.result.componentGroups &&
          data.result.componentGroups.length > 0
        ) {
          this.statusData = data.result;
          this.incidents = this.statusData.incidents;
          this.maintenances = this.statusData.maintenances;
          this.updateNotifications();
        }
      },
      (error) => {
        console.error('Error fetching component status:', error);
        // Handle error, e.g., show a toast message
        this.toastr.error('Failed to fetch component status. Please try again later.');
      }
    );
  }
  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements().subscribe(
      (data) => {
        if (data && data.success && data.result) {
          this.activeAnnouncements = data.result;
        }
        this.updateNotifications();
      },
      (error) => {
        console.error('Error fetching active announcements:', error);
        // Handle error, e.g., show a toast message
        this.toastr.error('Failed to fetch active announcements. Please try again later.');
      }
    );
  }
  updateNotifications() {
    this.totalNotifications =
      (this.activeAnnouncements ? this.activeAnnouncements.length : 0) +
      (this.incidents ? this.incidents.length : 0) +
      (this.maintenances ? this.maintenances.length : 0);
  }
  openEventDetail(id) {
    const dialogRef = this.dialog.open(EventDetailDialogComponent, {
      panelClass: 'full-dialog',
      data: id,
      width: '70%',
      height: '60%',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  authenticate(): void {
    this.user.Useslack().subscribe(
      response => {
        console.log(response)
        window.location.href = response.authUrl;
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

}
