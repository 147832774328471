import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../global';
import { user } from '../models/UserModel';

@Injectable({
  providedIn: 'root'
})

export class AdminApiService {
  apiURL = Globals.apiURL;
  constructor(private http: HttpClient) {

  }


  //* Reports *//
  GetReport(params) {
    return this.http.post<any>(this.apiURL + 'admin/reports', params);
  }

  GetUptimeReport(params) {
    return this.http.post<any>(this.apiURL + 'admin/reports/getuptimereports', params);
  }


  GetReportMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/reports/metadata');
  }
  //* Reports *//

  //* Components *//

  GetAllComponentsAndGroups(params) {
    return this.http.post<any>(this.apiURL + 'admin/component/allcomponentandgroup', params);
  }

  GetAllComponentGroups() {
    return this.http.get<any>(this.apiURL + 'admin/componentgroup');
  }

  GetComponentGroupById(param) {
    return this.http.get<any>(this.apiURL + 'admin/componentgroup/id/' + param);
  }

  GetComponentStatusMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/component/getcomponentstatusmetadata');
  }

  GetAllActiveTags() {
    return this.http.get<any>(this.apiURL + 'admin/component/getallactivetags');
  }

  AddComponentGroup(params) {
    return this.http.post<any>(this.apiURL + 'admin/componentgroup', params);
  }

  UpdateComponentGroup(params) {
    return this.http.put<any>(this.apiURL + 'admin/componentgroup', params);
  }

  AddComponent(params) {
    return this.http.post<any>(this.apiURL + 'admin/component', params);
  }

  UpdateComponent(params) {
    return this.http.put<any>(this.apiURL + 'admin/component', params);
  }

  GetComponentById(param) {
    return this.http.get<any>(this.apiURL + 'admin/component/id/' + param);
  }

  //* Components *//

  //* Incidents *//

  GetAllIncidents(params) {
    return this.http.post<any>(this.apiURL + 'admin/incident/GetAllIncidents', params);
  }

  GetIncidentMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/incident/getincidentmetadata');
  }



  allDashboard() {
    return this.http.get<any>(this.apiURL + 'admin/Dashboard/allDashboard');
  }
  getComponentCount() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/componentCount');
  }
  getComponentGroupCount() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/componentGroupCount');
  }
  getIncidentCount() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/incidentCount');
  }
  getSubscriberCount() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/subscriberCount');
  }
  getIncidentCountByType() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/incidentCountByType');
  }
  getIncidentCountByTypeAnnounce() {
    return this.http.get<any>(this.apiURL + 'admin/dashboard/incidentCountByTypeAnnounce');
  }
  GetAllRegionAndZones() {
    return this.http.get<any>(this.apiURL + 'admin/regionzone/getall');
  }

  AddIncident(params) {
    return this.http.post<any>(this.apiURL + 'admin/incident', params);
  }

  UpdateIncident(params) {
    return this.http.put<any>(this.apiURL + 'admin/incident', params);
  }

  DeleteIncident(params) {
    return this.http.put<any>(this.apiURL + 'admin/incident/Delete', params);
  }

  UpdateIncidentHistory(params: any) {

    var form_data = new FormData();

    for (var key in params) {
      form_data.append(key, params[key]);
    }
    return this.http.put<any>(this.apiURL + 'admin/incident/updatehistory', form_data);
  }

  GetIncidentById(param) {
    return this.http.get<any>(this.apiURL + 'admin/incident/id/' + param);
  }

  GetAllComponentGroupsWithRegionAndZone() {
    return this.http.get<any>(this.apiURL + 'admin/regionzone/getallcomponentgroupswithregionandzone');
  }

  downloadAttachmentFile(attachmentId): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' }
    return this.http.get<Blob>(this.apiURL + 'admin/incident/download/' + attachmentId, options);
  }

  //* Incidents *//


  //* Subscribers *//

  GetAllSubscribers(params) {
    return this.http.post<any>(this.apiURL + 'admin/subscriber/list', params);
  }

  UpdateSubscriberActiveState(params) {
    return this.http.put<any>(this.apiURL + 'admin/subscriber/updatesubscriberactivestate', params);
  }

  //* Settings*//
  AddUpdateSettings(param) {
    return this.http.post<any>(this.apiURL + 'admin/setting', param);
  }
  GetUpdadeImg(params){
    return this.http.put<any>(this.apiURL + 'admin/setting', params);
  }
  //UpdateSettings(param) {
  //  return this.http.put<any>(this.apiURL + 'admin/setting', param);
  //}
  GetSettings() {
    return this.http.get<any>(this.apiURL + 'admin/setting');
  }

  AddUser(param){
    return this.http.post<any>(this.apiURL + 'admin/user',param);
  }

  //Report Incident//
  ReportIncident(param){
    return this.http.post<any>(this.apiURL + 'admin/reportincident',param);
  }
  GetReportIncident(){
    return this.http.get<any>(this.apiURL + 'admin/reportincident');
  }

  downloadAttachment(attachmentUrl: string): Observable<Blob> {
    const url = `${this.apiURL}admin/reportincident/DownloadAttachment?attachmentUrl=${attachmentUrl}`;
    return this.http.get(url, { responseType: 'blob' });
  }
  GetLinechartData(param){
    return this.http.post<any>(this.apiURL + 'admin/Reports/LinechartData',param);
  }

  Addfeaturerequestmodals(formData: any) {
    return this.http.post(this.apiURL + 'Featurerequest', formData);
}
  getAllDropdwonValue(params){
    return this.http.post<any>(this.apiURL + 'admin/component/Filldropdown',params);
  }


  // uptime Apis

  AddUptime(params){
    return this.http.post<any>(this.apiURL + 'admin/UptimeTest/AddUptime',params);
  }

  getuptime(params){
    return this.http.post<any>(this.apiURL + 'admin/UptimeTest',params);
  }


  getUptimebyid(id){
    return this.http.get<any>(this.apiURL+'admin/UptimeTest/'+id)
  }

  UptimeUptime(params){
    return this.http.put<any>(this.apiURL + 'admin/UptimeTest',params);
  }

  DeleteUptime(id){
    return this.http.delete<any>(this.apiURL+'admin/UptimeTest/'+id)
  }

  GetUptimeLineChart(){
    return this.http.get<any>(this.apiURL+'admin/uptimehistory/getchart')
  }
  UptimeAction(param){
    return this.http.put<any>(this.apiURL+'admin/UptimeTest/UptimeAction',param)
  }


  DownloadCsv(){
    return this.http.get(this.apiURL+'admin/Subscriber/download',{ responseType: 'blob' })
  }

  uplodecsv(params){
    return this.http.post<any>(this.apiURL + 'admin/Subscriber/upload',params);
  }

  uptimehistory(id){
    return this.http.get<any>(this.apiURL+'admin/uptimehistory/'+id)
  }


  // login(param){
  //   return this.http.post<any>(this.apiURL + 'admin/user/login',param);
  // }
  login(userModel: user): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(this.apiURL  + 'admin/user/login', userModel , {headers});
  }


  //* Subscribers *//

  logout() {
    return this.http.post<any>(this.apiURL.replace('/api/', '/') + 'auth/logout', null);
  }



}
