import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AdminApiService } from "src/app/api/adminapi.service";

@Component({
  selector: "app-ping-history",
  templateUrl: "./uptime-history.component.html",
  styleUrls: ["./uptime-history.component.css"],
})
export class uptimeHistoryComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptionsHttp: DataTables.Settings = {};
  dtOptionsPing: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  uptimehistorylistData: any = [];
  ping: any = "";
  http: any = "";
  title: any = "";
  loading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private _adminApiService: AdminApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dtOptionsHttp = {
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"],
      ],
      ordering: true,
      pageLength: 10,

    };
    this.dtOptionsPing = {
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"],
      ],
      ordering: true,
      pageLength: 10,
    };
    this.route.params.subscribe((params: Params) => {
      const id = params['id'];
      if (id) {
        this.getuptimehistory(id);
        this.getTitle(id);
      }
    });

  }

  getuptimehistory(id: string) {

    this._adminApiService.uptimehistory(id).subscribe((data) => {
      this.uptimehistorylistData = data.result;
      this.reloadDataTable();
      this.loading = false;
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.reloadDataTable();
  }
  reloadDataTable(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }
  reset() {
    this.route.params.subscribe((params: Params) => {
      const id = params['id'];
      if (id) {
        this.getuptimehistory(id);
      }
    });
  }

  formatStartTime(startTime: string): string {
    const date = new Date(startTime);
    return this.datePipe.transform(date, "MMM dd, yyyy hh:mm a");
  }

  getTitle(id:string) {
    this._adminApiService.getUptimebyid(id).subscribe((data) => {
      if (data.success && data.result) {
        this.title = data.result.name;
        if(data.result.type == "HTTP"){
          this.http = data.result.type;
        }
        else if(data.result.type == "PING"){
          this.ping = data.result.type;
        }
      }
    });
  }
}
