import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'; // Importing FormControl from '@angular/forms' instead of 'UntypedFormControl'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/api/user.service';
import { AppComponent } from 'src/app/app.component';

export function confirmPasswordValidator(control: FormControl): { [key: string]: boolean } | null {
  const password = control.root.get('Password');
  const confirmPassword = control.value;

  if (password && confirmPassword && password.value !== confirmPassword) {
    return { 'passwordMismatch': true };
  }

  return null;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup; // Renamed from signInForm
  showPassword: boolean = false;
  ConfirmshowPassword: boolean = false;

  isValidFormSubmitted = null;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.initForm();
    let id = this.route.snapshot.paramMap.get('id');
    this.getEmail(id);
  }

  initForm(): void {
    this.changePasswordForm = new FormGroup({
      UserId: new FormControl(''),
      Password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")]),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ConfirmPassword: new FormControl('', [Validators.required, confirmPasswordValidator]),
    });
  }

  sendpasswordChange(): void {
    debugger;
    this.changePasswordForm.updateValueAndValidity();
    this.isValidFormSubmitted = false;

    if (this.changePasswordForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", { closeButton: true });
      AppComponent.hideLoader();
      return;
    }

    this.isValidFormSubmitted = true;

    const requestPayload = this.prepareRequestModel();

    AppComponent.showLoader();

    this._userService.changePaaword(requestPayload) // Fixed typo
    .subscribe(
      data => {
        this.toastr.success("Password Changed Successfully", "Success", { closeButton: true });
        if (data.success && data.result) {
          this.changePasswordForm.get('UserId').setValue(data.result.UserId);
        }
        this.reset();
        this.router.navigate(['/signin']);
        AppComponent.hideLoader();
      },
      error => {
        if (error && error.error && error.error.message){

          this.toastr.error(error.error.message, "Error", { closeButton: true });
          this.resetPasswordFields();
        }
        else
          this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

        AppComponent.hideLoader();
      }
    );
  }

  prepareRequestModel(): any {
    const requestModel = {
      UserId: this.route.snapshot.paramMap.get('id'),
      Email: this.changePasswordForm.get('Email').value,
      Password: this.changePasswordForm.get('Password').value,
      ConfirmPassword: this.changePasswordForm.get('ConfirmPassword').value,
    };

    return requestModel;
  }

  confirmpassword(){
    if(this.f.password.value != this.f.confirmPassword.value){
      console.log('password not match');
    }
  }

  reset(): void {
    this.changePasswordForm.reset();
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  ConfirmtogglePasswordVisibility(): void {
    this.ConfirmshowPassword = !this.ConfirmshowPassword;
  }

  getEmail(userId: string): void {
    this._userService.getEmailFromUserId(userId).subscribe((data: any) => {
      console.log(data);
      if (typeof data === 'object' && data !== null && 'email' in data) {
        this.changePasswordForm.patchValue({
          Email: data.email,
        });
      } else {
        console.error('Invalid data format or missing email property');
      }
    });
  }

  get f() {
    return this.changePasswordForm.controls;
  }
  resetPasswordFields(): void {
    this.changePasswordForm.get('Password').reset();
    this.changePasswordForm.get('ConfirmPassword').reset();
  }
}
