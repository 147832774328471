import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  constructor() { }

  private rowDataSubject = new BehaviorSubject<any>(null);
  public rowData$ = this.rowDataSubject.asObservable();
debugger;
  sendRowData(item: any) {
    debugger;
    this.rowDataSubject.next(item);
  }
}
