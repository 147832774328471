import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sharedService } from '../../api/sharedService';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  currentYear;
  constructor(private router: Router, private sharedService: sharedService) {
    this.currentYear = new Date().getUTCFullYear();
  }

  ngOnInit() {
    // const currentTheme = this.sharedService.getCurrentTheme();
    // console.log(currentTheme);
    // if (currentTheme === 'Aurora') {
    //   this.router.navigate(['/home3']);
    // }
    // else if (currentTheme === 'Bluelack') {
    //   this.router.navigate(['/home2']);
    // }
    // else {
    //   this.router.navigate(['']);
    // }

  }

}
