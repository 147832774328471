import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatusApiService } from '../../../api/statusapi.service';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../global';
import { CGRZChecklistDatabase } from '../../../models/CGRZCheckListDatabase';
import { CGItemFlatNode, CGItemNode, ComponentDetail } from '../../../models/treeview';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  confirmNow: boolean = false;
  subscribedComponents = [];
  cgregionZones = [];
  isAllSelected = false;
  newSubscriber = false;
  unsubscribe = false;

  subscriberForm = new UntypedFormGroup({
    subscriberId: new UntypedFormControl(''),
    subscriberType: new UntypedFormControl(''),
    subscriptionValue: new UntypedFormControl(''),
    isActive: new UntypedFormControl(false),
    confirmed: new UntypedFormControl(false),
    confirmedDate: new UntypedFormControl(''),
  });

  constructor(private route: ActivatedRoute, private toastr: ToastrService,
    private _statusApiService: StatusApiService, private cdRef: ChangeDetectorRef,
    private cgrzdb: CGRZChecklistDatabase, private cdref: ChangeDetectorRef, private router: Router) {
    this.initiateTreeControl();
  }

  async ngOnInit() {
    await this.getAllComponentRegionsAndZones();
    let isConfirmed = this.route.snapshot.queryParams['confirm'];
    let isManage = this.route.snapshot.queryParams['manage'];
    this.newSubscriber = this.route.snapshot.queryParams['newsubscribe'];

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.f.subscriberId.setValue(id);
      this.setModel();

      if (isConfirmed) {
        this.confirmNow = true;
        this.applySubscriptionConfirmation();
      }
    }
  }

  initiateTreeControl() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<CGItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.cgrzdb.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  get f() {
    return this.subscriberForm.controls;
  }

  applySubscriptionConfirmation() {
    AppComponent.showLoader();

    this._statusApiService.ConfirmSubscriber(this.f.subscriberId.value)
      .subscribe(
        data => {
          this.toastr.success("Congratulations! You have successfully subscribed to iTechOps Status.", "Subscribed!", { closeButton: true });
          AppComponent.hideLoader();
        },
        error => {
          this.toastr.error("Something Went Wrong.", "Error", { closeButton: true });
          console.log(error);
          AppComponent.hideLoader();

        }
      );
  }


  ResendConfirmationEmail() {
    AppComponent.showLoader();

    this._statusApiService.ResendConfirmationEmail(this.f.subscriberId.value)
      .subscribe(
        data => {
          this.toastr.success("Check your email inbox and confirm your subscription.", "Success", { closeButton: true });
          this.confirmNow = true;
          AppComponent.hideLoader();
        },
        error => {
          this.toastr.error("Something Went Wrong.", "Error", { closeButton: true });
          console.log(error);
          AppComponent.hideLoader();

        }
      );
  }

  Unsubscribe() {
    AppComponent.showLoader();

    this._statusApiService.Unsubscribe(this.f.subscriberId.value)
      .subscribe(
        data => {
          this.toastr.success("You have successfully unsubscribed from iTechOps Status.", "Subscribed!", { closeButton: true });
          this.setModel();
          AppComponent.hideLoader();
        },
        error => {
          this.toastr.error("Something Went Wrong.", "Error", { closeButton: true });
          console.log(error);
          AppComponent.hideLoader();

        }
      );
  }

  cancel() {
    this.router.navigate(['/itechopsstatushome']);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  prepareRequestModel() {
    const reequestModel = <any>{};

    reequestModel.subscriberId = this.f.subscriberId.value;
    reequestModel.subscriberType = this.f.subscriberType.value;
    reequestModel.subscriptionValue = this.f.subscriptionValue.value;
    reequestModel.isActive = this.f.isActive.value;
    reequestModel.confirmed = this.f.confirmed.value;
    reequestModel.subscribedComponents = this.getSeletedComponents();

    return reequestModel;
  }

  onFormSubmit() {

    const requestPayload = this.prepareRequestModel();

    //if (requestPayload.subscribedComponents.length <= 0) {
    //  this.toastr.error("Please Add Atlease one Component to Subscribe.", "Error", { closeButton: true });
    //  AppComponent.hideLoader();
    //  return;
    //}

    AppComponent.showLoader();
    this._statusApiService.AddUpdateSubscriber(requestPayload)
      .subscribe(
        data => {
          this.toastr.success("Your subscription preferences have been updated.", "Updated!", { closeButton: true });
          this.router.navigate([Globals.homePageURL]);

          this.setModel();
        },
        error => {
          if (error && error.error && error.error.message && error.error?.result)
            this.toastr.error(error.error.message + " " + error.error?.result[0], "Error", { closeButton: true });
          else if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
      );
  }

  setModel() {
    AppComponent.showLoader();

    this._statusApiService.GetSubscriberDetails(this.f.subscriberId.value)
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.f.subscriberType.setValue(data.result.subscriberType);
            this.f.subscriptionValue.setValue(data.result.subscriptionValue);
            this.f.isActive.setValue(data.result.isActive);
            this.f.confirmed.setValue(data.result.confirmed);
            this.f.confirmedDate.setValue(data.result.confirmedDate);

            this.subscribedComponents = data.result.subscribedComponents;
            setTimeout(() => {
              this.setComponentSelection(data.result.subscribedComponents);
            }, 500);


            if (this.newSubscriber && !this.f.confirmed.value) {
              this.toastr.success("Check your email inbox and confirm your subscription.", "Success", { closeButton: true });
            }
          }
          AppComponent.hideLoader();
        },
        error => {
          this.toastr.error("Something Went Wrong.", "Error", { closeButton: true });
          console.log(error);
          AppComponent.hideLoader();
        }
      );
  }

 

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    else
      return string;
  }

  isConfirmationPending() {
    if (this.f.confirmed.value == true || this.confirmNow) {
      return false;
    }
    return true;
  }


  async getAllComponentRegionsAndZones() {
    this._statusApiService.GetAllComponentGroupsWithRegionAndZone()
      .subscribe(
        data => {
          if (data && data.success && data.result) {

            if (data.result && data.result.length > 0) {
              this.cgregionZones = data.result;
              this.cgrzdb.initialize(this.cgregionZones);
              //this.treeControl.expandAll();

              this.cdRef.detectChanges();

            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  flatNodeMap = new Map<CGItemFlatNode, CGItemNode>();
  nestedNodeMap = new Map<CGItemNode, CGItemFlatNode>();
  selectedParent: CGItemFlatNode | null = null;
  treeControl: FlatTreeControl<CGItemFlatNode>;
  treeFlattener: MatTreeFlattener<CGItemNode, CGItemFlatNode>;
  dataSource: MatTreeFlatDataSource<CGItemNode, CGItemFlatNode>;
  checklistSelection = new SelectionModel<CGItemFlatNode>(true);

  getLevel = (node: CGItemFlatNode) => node.level;
  isExpandable = (node: CGItemFlatNode) => node.expandable;
  getChildren = (node: CGItemNode): CGItemNode[] => node.children;
  hasChild = (_: number, _nodeData: CGItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: CGItemFlatNode) => !_nodeData.item;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: CGItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new CGItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: CGItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }


  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: CGItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: CGItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: CGItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: CGItemFlatNode): void {
    let parent: CGItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }

    this.updateIsAllSelected();
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: CGItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
    this.getSeletedComponents();
  }

  /* Get the parent node of a node */
  getParentNode(node: CGItemFlatNode): CGItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSeletedComponents() {
    let itemNode: ComponentDetail[] = [];
    this.checklistSelection.selected.forEach((item) => {

      let component = itemNode?.filter(x => x.componentId == item.item.componentId
        && x.regionId == item.item.regionId && x.zoneId == item.item.zoneId);

      if (item.level == 2 && (component == null || component?.length <= 0)) {
        let componentDetail = new ComponentDetail();
        componentDetail.componentId = item.item.componentId;
        componentDetail.componentName = item.item.componentName;
        componentDetail.regionId = item.item.regionId;
        componentDetail.regionName = item.item.regionName;
        componentDetail.zoneId = item.item.zoneId;
        componentDetail.zoneName = item.item.zoneName;
        itemNode.push(componentDetail);
      }
    });
    return itemNode;
  }

  setComponentSelection(components) {
    if (components) {
      components.forEach((component) => {
        this.treeControl.dataNodes.forEach((c) => {
          if (c.item && c.item.zoneId && c.item.zoneId == component.zoneId
            && c.item.regionId == component.regionId && c.item.componentId == component.componentId) {
            this.checklistSelection.select(c);
            this.checkAllParentsSelection(c);

            if (c.level == 2) {
              let parentNode = this.getParentNode(c);
              this.treeControl.expand(this.getParentNode(parentNode));
              this.treeControl.expand(parentNode)
            }
          }
        });
      });
    }

    this.updateIsAllSelected();
  }

  selectDeselectAllComponents(isSelect) {
    this.treeControl.dataNodes.forEach((node) => {

      if (isSelect)
        this.checklistSelection.select(node);
      else
        this.checklistSelection.deselect(node);

      this.checkAllParentsSelection(node);
    });
    this.updateIsAllSelected();
  }

  updateIsAllSelected() {
    setTimeout(() => {
      this.isAllSelected = this.treeControl.dataNodes.length == this.checklistSelection.selected.length;
    }, 500);
  }
}
