import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatusApiService } from '../../../api/statusapi.service';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../global';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  isValidFormSubmitted = null;
  subscribeForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private _statusApiService: StatusApiService,
    private toastr: ToastrService, public router: Router,
    ) {
  }

  ngOnInit() {
    this.addRecaptchaScript();
  }
 async validate(event) {
    event.preventDefault();
    if (this.subscribeForm.invalid) {
      AppComponent.hideLoader();
      return;
    }
    if (typeof window['grecaptcha'] === 'function') {
        window['grecaptcha'].execute();
    } else {
        console.error('reCAPTCHA not loaded yet');
    }
}

async renderReCaptcha() {
    window['grecaptcha'].ready(() => {
        window['grecaptcha'].render(this.recaptchaElement?.nativeElement, {
            'sitekey': Globals.recaptchaSiteKey,
            'callback': (response) => {
                this.onFormSubmit();
                window['grecaptcha'].reset();
            },
            'size': 'invisible'
        });
    });
}


  async addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }
  get f() {
    return this.subscribeForm.controls;
  }

  resolved(event) {
    if (event)
      this.onFormSubmit();
  }

  onFormSubmit() {
    this.isValidFormSubmitted = false;
    if (this.subscribeForm.invalid) {
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const requestPayload = { subscriptionValue: this.f.email.value, subscriberType: 'email' };

    AppComponent.showLoader();
    this._statusApiService.AddUpdateSubscriber(requestPayload)
      .subscribe(
        data => {
          if (data.success && data.result) {
            window.location.href = '/subscription/' + data.result + '?newsubscribe=true';
          }

          setTimeout(() => {
            AppComponent.hideLoader();
          },200);
        },
        error => {
          if (error && error.error && error.error.message && error.error?.result)
            this.toastr.error(error.error?.result[0], "Error", { closeButton: true });
          else if (error && error.error && error.error.message)
            this.toastr.error(error.error.message, "Error", { closeButton: true });
          else
            this.toastr.error("Something Went Wrong. Please contact Admin", "Error", { closeButton: true });

          AppComponent.hideLoader();
        }
      );

  }

  
}
