import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { AdminApiService } from "src/app/api/adminapi.service";
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  private dataSubscription: Subscription;
  private routeSubscription: Subscription;

  uptimechartData: Array<Object> = [
    { name: "By Monitor", value: "" },
    { name: "By Month", value: "month" },
    { name: "By Week", value: "week" },
  ];
  selectedView: string = 'monitor';

  constructor(private _adminApiService: AdminApiService,private route: ActivatedRoute,
    private router: Router) {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.fetchChartData();
    });
  }

  ngOnInit() {
    this.initializeChartOptions();
    this.fetchChartData();
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  initializeChartOptions() {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "",
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        type: "category",
        categories: [],
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: (val) => {
            return val.toFixed(0) + "%";
          }
        }
      }
    };
  }

  fetchChartData() {
    this.dataSubscription = this._adminApiService.GetUptimeLineChart()
      .subscribe(
        (data) => {
          this.handleChartData(data);
        },
        (error) => {
          console.error("Error fetching chart data:", error);
        }
      );
  }

  handleChartData(data) {
    let categories, seriesData;
    switch (this.selectedView) {
      case "monitor":
        categories = data.result.item1.map(item => item.testName);
        seriesData = [{
          name: 'Test Pass Percentage',
          data: data.result.item1.map(item => item.percentage)
        }];
        break;
      case "month":
        categories = data.result.item2.map(item => item.month);
        seriesData = [{
          name: 'Test Pass Percentage',
          data: data.result.item2.map(item => item.percentage)
        }];
        break;
      case "week":
        categories = data.result.item3.map(item => item.week);
        seriesData = [{
          name: 'Test Pass Percentage',
          data: data.result.item3.map(item => item.percentage)
        }];
        break;
      default:
        categories = data.result.item1.map(item => item.testName);
        seriesData = [{
          name: 'Test Pass Percentage',
          data: data.result.item1.map(item => item.percentage)
        }];
    }
    this.updateChart(categories, seriesData);
  }

  updateChart(categories: any[], seriesData: any[]) {
    this.chartOptions = {
      ...this.chartOptions,
      series: seriesData,
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: categories
      }
    };
  }

  onDropdownChange(event: any) {
    const selectedView = event.target.value;
    if (selectedView !== this.selectedView) {
      this.selectedView = selectedView;
      this.fetchChartData();
    }
  }
}
