import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/api/user.service';
import { AppComponent } from 'src/app/app.component';

export interface UserModel {
  email: string;
}


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {


  signInForm: FormGroup;

  constructor(private userService:UserService,private toastr: ToastrService,private formBuilder: FormBuilder,private router: Router,) {
    this.signInForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  sendEmail() {
    if (this.signInForm.invalid) {
      this.toastr.error('Please enter a valid email address', 'Error', { closeButton: true });
      return;
    }

    const email = this.signInForm.get('Email').value;

    if (!email) {
      this.toastr.error('Please enter your email address', 'Error', { closeButton: true });
      return;
    }

    const user: UserModel = { email };
    AppComponent.showLoader();

    this.userService.forgotpassword(user).subscribe(
      (data) => {
        // Handle success response
        console.log(data);
        this.toastr.success('Email Send Successfully,Check your email and reset password.', 'Success', { closeButton: true });
        this.signInForm.reset();
        this.router.navigate(['/signin']);
        AppComponent.hideLoader();
      },
      (error) => {
        // Handle error response
        console.error(error);
        this.toastr.error('Failed to send email', 'Error', { closeButton: true });
        AppComponent.hideLoader();
      }
    );
  }

}
