import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import {
  ImageCroppedEvent,
  LoadedImage,
  base64ToFile,
} from "ngx-image-cropper";
//import * as moment from 'moment-timezone';
import { ToastrService } from "ngx-toastr";
import { AdminApiService } from "../../../api/adminapi.service";
import { AppComponent } from "../../../app.component";
//import timezonesData from '../../../timezones.json';
import * as changeurl from "e2e/src/base.js";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { UserService } from "src/app/api/user.service";
//import { request } from 'http';

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  timeZones: string[] = [
    "Australian",
    "Afghanistan",
    "Brasilia",
    "Bangladesh",
    "Central",
    "Eastern",
    "Greenwich",
    "HongKong",
    "Indian",
    "Coordinated",
  ];
  timeZoneShortMap: { [key: string]: string } = {
    Australian: " ACST",
    Afghanistan: "AFT",
    Brasilia: "BRT",
    Bangladesh: "BST",
    Central: "CAT",
    Eastern: "EST",
    Greenwich: "GMT",
    HongKong: "HKT",
    Indian: "IST",
    Coordinated: "UTC",
  };

  pageTitle: string = "Settings";
  isValidFormSubmitted = null;
  isNew = true;
  isEdit = true;
  imageSrc: string = "assets/image/logodummy.svg";
  selectedTimezone: string;
  useLogoLink: boolean = false;
  Local: boolean = false;
  SAML: boolean = false;
  hideButton: boolean = false;
  announcementValue: string = 'No';
  announcementDescription: string = '';

  settingsForm = new UntypedFormGroup({
    Id: new UntypedFormControl(""),
    Logo: new UntypedFormControl(""),
    LogoLink: new UntypedFormControl(""),
    Title: new UntypedFormControl(""),
    AboutSite: new UntypedFormControl(""),
    WebsiteUrl: new UntypedFormControl(""),
    FaviconUrl: new UntypedFormControl(""),
    DomainUrl: new UntypedFormControl(""),
    PortalUrl: new UntypedFormControl(""),
    PhoneNo: new UntypedFormControl(""),
    TwitterId: new UntypedFormControl(""),
    LinkedinUrl: new UntypedFormControl(""),
    FooterDescription: new UntypedFormControl(""),
    FooterLink: new UntypedFormControl(""),
    SlackLink:new UntypedFormControl(""),
    TimeZone: new UntypedFormControl(""),
    TimeZoneShort: new UntypedFormControl(""),
    CurrentTheme: new UntypedFormControl(""),
    IsMultiRegion: new UntypedFormControl(""),
    IsMultiZone: new UntypedFormControl(""),
    OrgName: new UntypedFormControl(""),
    CustomSubmitIncidentURLEnabled: new UntypedFormControl(""),
    CustomSubmitIncidentURL: new UntypedFormControl(""),
    Local: new UntypedFormControl(""),
    SAML: new UntypedFormControl(""),
    GlobalAnnouncement : new UntypedFormControl("")
  });

  selectedFile: any;
  LogoLink: any;
  imageChangedEvent: any;
  croppedImage: any;
  currentTheme: string;
  selectedTheme: string;
  showLogoLink: boolean = false;
  OrgName: any;
  organization: any;
  current: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _adminApiService: AdminApiService,
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private _userService: UserService
  ) /*private cookieService: CookieService*/ {}

  ngOnInit(): void {
    debugger
    this.getSettings();
    this.checkValidation();
    this.btnhideimg();
    if (!this.isAdminRole()) {
      this.router.navigate(['/admin/events']);
    }
    
  }

  get f() {
    return this.settingsForm.controls;
  }
  onFormSubmit() {
    this.isValidFormSubmitted = false;
    console.log(this.settingsForm);
    console.log(this.organization);
    if (this.settingsForm.invalid) {
      this.toastr.error("Please fix validation errors to continue", "Error", {
        closeButton: true,
      });
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const requestPayload = this.prepareRequestModel();

    AppComponent.showLoader();
    const formData = new FormData();

    this.selectedTheme = requestPayload.CurrentTheme;
    debugger
    if (this.selectedFile) {
      formData.append("Logo", this.selectedFile);      
    } else {
      formData.append("LogoLink", requestPayload.LogoLink);
    }
    formData.append("PhoneNo", requestPayload.PhoneNo);

    formData.append("LogoLink", requestPayload.LogoLink);
    formData.append("Title", requestPayload.Title);
    formData.append("AboutSite", requestPayload.AboutSite);
    formData.append("WebsiteUrl", requestPayload.WebsiteUrl);
    formData.append("FaviconUrl", requestPayload.FaviconUrl);
    formData.append("DomainUrl", requestPayload.DomainUrl);
    formData.append("PortalUrl", requestPayload.PortalUrl);
    formData.append("TwitterId", requestPayload.TwitterId);
    formData.append("LinkedinUrl", requestPayload.LinkedinUrl);
    formData.append("FooterDescription", requestPayload.FooterDescription);
    formData.append("FooterLink", requestPayload.FooterLink);
    formData.append("SlackLink",requestPayload.SlackLink)
    formData.append("TimeZone", requestPayload.TimeZone);
    formData.append("TimeZoneShort", requestPayload.TimeZoneShort);
    formData.append("CurrentTheme", requestPayload.CurrentTheme);
    formData.append("OrgName", requestPayload.OrgName);
    formData.append("GlobalAnnouncement",requestPayload.GlobalAnnouncement);
    if (requestPayload.Local == true) {
      formData.append("Local", requestPayload.Local);
    }

    if (requestPayload.SAML == true) {
      formData.append("SAML", requestPayload.SAML);
    }
    if (requestPayload.IsMultiRegion == true) {
      formData.append("IsMultiRegion", requestPayload.IsMultiRegion);
    }
    if (requestPayload.IsMultiZone == true) {
      formData.append("IsMultiZone", requestPayload.IsMultiZone);
    }
    if (requestPayload.CustomSubmitIncidentURLEnabled == true) {
      formData.append(
        "CustomSubmitIncidentURLEnabled",
        requestPayload.CustomSubmitIncidentURLEnabled
      );
      formData.append(
        "CustomSubmitIncidentURL",
        requestPayload.CustomSubmitIncidentURL
      );
    }

    // formData.append('CustomSubmitIncidentURLEnabled',requestPayload.CustomSubmitIncidentURLEnabled);
    // formData.append('CustomSubmitIncidentURL',requestPayload.CustomSubmitIncidentURL);
    // formData.append('Local', requestPayload.Local);
    // formData.append('SAML', requestPayload.SAML);
    // formData.append('IsMultiRegion', requestPayload.IsMultiRegion);
    // formData.append('IsMultiZone', requestPayload.IsMultiZone);
    
    this._adminApiService.AddUpdateSettings(formData).subscribe(
      (data) => {
        this.toastr.success("Settings save Successfully", "Success", {
          closeButton: true,
        });
        if (data.success && data.result) {
          this.f.Id.setValue(data.result.Id);
          this.cookieService.set('iSetting', JSON.stringify(data.result));
          this.cookieService.set('theme', data.result.currentTheme);
        }
        // if(data.result.logo == null ){
        //   data.result.logo = this.imageSrc;
        //   this.imageSrc = data.result.logoPath;
        //   }
        this.btnhideimg();
        console.log(this.selectedTheme);
        this.router.navigate(["/admin/settings"]);
        AppComponent.hideLoader();
      },
      (error) => {
        if (error && error.error && error.error.message)
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        else
          this.toastr.error(
            "Something Went Wrong. Please contact Admin",
            "Error",
            { closeButton: true }
          );
        AppComponent.hideLoader();
      }
    );
    // const theme= requestPayload.CurrentTheme;
    // this.cookieService.set('theme',theme);
  }

  onLogoFileSelected(event: any): void {
    this.displayStyle = "block";
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
      //this.imageSrc = this.croppedImage
    };
    reader.readAsDataURL(file);
    this.selectedFile = event.target.files[0];
  }

  imageCropped(event: ImageCroppedEvent) {
    const orgname = this.organization;
    console.log(orgname);

    const timestamp = this.getFormattedTimestamp();

    const FileName = `${orgname}_${timestamp}.png`;

    let existingFileName = this.selectedFile?.name;
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
    this.selectedFile = new File([base64ToFile(this.croppedImage)], FileName);
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
  saveCroppedImage() {
    this.imageSrc = this.croppedImage;
    this.displayStyle = "none";
  }

  prepareRequestModel() {
    const reequestModel = <any>{};

    if (this.f.Id.value) reequestModel.Id = this.f.Id.value;
    reequestModel.Logo = this.f.Logo.value;
    reequestModel.LogoLink = this.f.LogoLink.value;
    if (this.f.Title.value == null) {
      reequestModel.Title = "";
    } else {
      reequestModel.Title = this.f.Title.value;
    }

    reequestModel.AboutSite = this.f.AboutSite.value;
    reequestModel.WebsiteUrl = this.f.WebsiteUrl.value;
    reequestModel.FaviconUrl = this.f.FaviconUrl.value;
    reequestModel.DomainUrl = this.f.DomainUrl.value;
    reequestModel.PortalUrl = this.f.PortalUrl.value;
    if (this.f.PhoneNo.value == null) {
      reequestModel.PhoneNo = "";
    } else {
      reequestModel.PhoneNo = this.f.PhoneNo.value;
    }

    if (this.f.TwitterId.value == null) {
      reequestModel.TwitterId = "";
    } else {
      reequestModel.TwitterId = this.f.TwitterId.value;
    }
    if (this.f.LinkedinUrl.value == null) {
      reequestModel.LinkedinUrl = "";
    } else {
      reequestModel.LinkedinUrl = this.f.LinkedinUrl.value;
    }
    if (this.f.FooterDescription.value == null) {
      reequestModel.FooterDescription = "";
    } else {
      reequestModel.FooterDescription = this.f.FooterDescription.value;
    }
    reequestModel.FooterLink = this.f.FooterLink.value;
    reequestModel.SlackLink = this.f.SlackLink.value;
    reequestModel.TimeZone = this.f.TimeZone.value;
    reequestModel.TimeZoneShort = this.f.TimeZoneShort.value;
    reequestModel.CurrentTheme = this.f.CurrentTheme.value;
    reequestModel.IsMultiRegion = this.f.IsMultiRegion.value;
    reequestModel.IsMultiZone = this.f.IsMultiZone.value;
    reequestModel.OrgName = this.f.OrgName.value;
    reequestModel.CustomSubmitIncidentURLEnabled =
      this.f.CustomSubmitIncidentURLEnabled.value;
    if (this.f.CustomSubmitIncidentURLEnabled.value == true) {
      reequestModel.CustomSubmitIncidentURL =
        this.f.CustomSubmitIncidentURL.value;
    } else {
      reequestModel.CustomSubmitIncidentURL = "";
    }
    reequestModel.Local = this.f.Local.value;
    reequestModel.SAML = this.f.SAML.value;
    reequestModel.GlobalAnnouncement = this.f.GlobalAnnouncement.value;

    return reequestModel;
  }

  reset() {
    this.f.Id.setValue("");
    this.f.Logo.setValue("");
    this.f.LogoLink.setValue("");
    this.f.Title.setValue("");
    this.f.AboutSite.setValue("");
    this.f.WebsiteUrl.setValue("");
    this.f.FaviconUrl.setValue("");
    this.f.DomainUrl.setValue("");
    this.f.PortalUrl.setValue("");
    this.f.PhoneNo.setValue("");
    this.f.TwitterId.setValue("");
    this.f.LinkedinUrl.setValue("");
    this.f.FooterDescription.setValue("");
    this.f.FooterLink.setValue("");
    this.f.SlackLink.setValue("");
    this.f.TimeZone.setValue("");
    this.f.TimeZoneShort.setValue("");
    this.f.CurrentTheme.setValue("");
    this.f.IsMultiRegion.setValue("");
    this.f.IsMultiZone.setValue("");
    this.f.OrgName.setValue("");
    this.f.CustomSubmitIncidentURLEnabled.setValue("");
    this.f.CustomSubmitIncidentURL.setValue("");
    this.f.Local.setValue("");
    this.f.SAML.setValue("");
    this.f.GlobalAnnouncement.setValue("");
  }

  getSettings() {
    // Make the API call to retrieve settings data
    this._adminApiService.GetSettings().subscribe(
      (data) => {
        // Handle the response data and update the form controls
        if (data.success && data.result) {
          data.result = data.result[0];
          //this.f.Id.setValue(data.result.id);
          // this.f.Logo.setValue(data.result.logo);
          // this.f.LogoLink.setValue(data.result.logoLink);
          // this.f.Title.setValue(data.result.title);
          // this.f.AboutSite.setValue(data.result.aboutSite);
          // this.f.WebsiteUrl.setValue(data.result.websiteUrl);
          // this.f.FaviconUrl.setValue(data.result.faviconUrl);
          // this.f.DomainUrl.setValue(data.result.domainUrl);
          // this.f.PortalUrl.setValue(data.result.portalUrl);
          // this.f.PhoneNo.setValue(data.result.phoneNo);
          // this.f.TwitterId.setValue(data.result.twitterId);
          // this.f.LinkedinUrl.setValue(data.result.linkedinUrl);
          // this.f.FooterDescription.setValue(data.result.footerDescription);
          // this.f.FooterLink.setValue(data.result.footerLink);
          // this.f.TimeZone.setValue(data.result.timeZone);
          // this.f.TimeZoneShort.setValue(data.result.timeZoneShort);
          // this.f.CurrentTheme.setValue(data.result.currentTheme);
          // this.f.IsMultiRegion.setValue(data.result.isMultiRegion);
          // this.f.IsMultiZone.setValue(data.result.isMultiZone);
          // this.f.OrgName.setValue(data.result.orgName);
          // this.organization = data.result.orgName;
          // this.imageSrc = data.result.logoLink;
          this.setTitleOrEmpty(this.f.Logo, data.result.logo);
          this.setTitleOrEmpty(this.f.LogoLink, data.result.logoLink);
          this.setTitleOrEmpty(this.f.Title, data.result.title);
          this.setTitleOrEmpty(this.f.AboutSite, data.result.aboutSite);
          this.setTitleOrEmpty(this.f.WebsiteUrl, data.result.websiteUrl);
          this.setTitleOrEmpty(this.f.FaviconUrl, data.result.faviconUrl);
          this.setTitleOrEmpty(this.f.DomainUrl, data.result.domainUrl);
          this.setTitleOrEmpty(this.f.PortalUrl, data.result.portalUrl);
          this.setTitleOrEmpty(this.f.PhoneNo, data.result.phoneNo);
          this.setTitleOrEmpty(this.f.TwitterId, data.result.twitterId);
          this.setTitleOrEmpty(this.f.LinkedinUrl, data.result.linkedinUrl);
          this.setTitleOrEmpty(
            this.f.FooterDescription,
            data.result.footerDescription
          );
          this.setTitleOrEmpty(this.f.FooterLink, data.result.footerLink);
          this.setTitleOrEmpty(this.f.SlackLink, data.result.slackLink);
          this.setTitleOrEmpty(this.f.TimeZone, data.result.timeZone);
          this.setTitleOrEmpty(this.f.TimeZoneShort, data.result.timeZoneShort);
          this.setTitleOrEmpty(this.f.IsMultiRegion, data.result.isMultiRegion);
          this.setTitleOrEmpty(this.f.IsMultiZone, data.result.isMultiZone);
          this.setTitleOrEmpty(this.f.CurrentTheme, data.result.currentTheme);
          this.setTitleOrEmpty(this.f.OrgName, data.result.orgName);
          this.setTitleOrEmpty(
            this.f.CustomSubmitIncidentURLEnabled,
            data.result.customSubmitIncidentURLEnabled
          );
          if (data.result.customSubmitIncidentURLEnabled == true) {
            this.setTitleOrEmpty(
              this.f.CustomSubmitIncidentURL,
              data.result.customSubmitIncidentURL
            );
          }
          this.setTitleOrEmpty(this.f.Local, data.result.local);
          this.setTitleOrEmpty(this.f.SAML, data.result.saml);
          this.setTitleOrEmpty(this.f.GlobalAnnouncement,data.result.globalAnnouncement);
          this.organization = data.result.orgName;
          this.imageSrc = data.result.logoLink;
          this.isNew = false;
        }
      },
      (error) => {
        if (error && error.error && error.error.message)
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        else
          this.toastr.error(
            "Something Went Wrong. Please contact Admin",
            "Error",
            { closeButton: true }
          );

        AppComponent.hideLoader();
      }
    );
  }

  private setTitleOrEmpty(control: AbstractControl, value: any): void {
    control.setValue(value === "null" ? "" : value);
  }

  onIsMultiRegionChange() {
    const currentThemeControl = this.settingsForm.get('CurrentTheme');
  const isMultiRegionValue = this.settingsForm.get('IsMultiRegion').value;

  if (isMultiRegionValue) {
    currentThemeControl.enable();
  } else {
    currentThemeControl.disable();
    // Optionally, reset the value to an initial/default value
    currentThemeControl.setValue("Default");

  }
  }

  customSubmitIncidentURLEnabled() {
    const CustomSubmitIncidentUrlControl = this.settingsForm.get(
      "CustomSubmitIncidentURL"
    );

    if (!this.settingsForm.get("CustomSubmitIncidentURLEnabled").value) {
      CustomSubmitIncidentUrlControl?.enable();
    } else {
      CustomSubmitIncidentUrlControl.disable();
    }
  }

  showLogoLinkField() {
    // this.getSettings();
    this.useLogoLink = !this.useLogoLink;
    if (this.useLogoLink) {
      this.settingsForm.get("LogoLink").enable();
    } else {
      this.settingsForm.get("LogoLink").disable();
    }
  }
  updateTimeZoneShort(event: Event) {
    const timeZoneControl = this.settingsForm.get("TimeZone");
    const timeZoneShortControl = this.settingsForm.get("TimeZoneShort");

    if (timeZoneControl && timeZoneShortControl) {
      const selectedTimeZone = timeZoneControl.value;
      const shortName = this.timeZoneShortMap[selectedTimeZone] || "";
      timeZoneShortControl.setValue(shortName);
    }
  }
  checkValidation() {
    this.f.LogoLink.setValidators([Validators.pattern("^.*$")]);
    this.f.Title.setValidators([Validators.required]);
    this.f.OrgName.setValidators([Validators.required]);        
    this.f.AboutSite.setValidators([Validators.required]);
    this.f.WebsiteUrl.setValidators([
      Validators.required,
      Validators.pattern("^(https?://).*$"),
    ]);
    this.f.FaviconUrl.setValidators([Validators.pattern("^(https?://).*$")]);
    this.f.DomainUrl.setValidators([Validators.pattern("^(https?://).*$")]);
    this.f.PortalUrl.setValidators([Validators.pattern("^(https?://).*$")]);
    this.f.TwitterId.setValidators([
      Validators.pattern(
        /^https?:\/\/(?:www\.)?twitter\.com\/[A-Za-z0-9_]{1,15}(\/)?$/
      ),
    ]);
    this.f.LinkedinUrl.setValidators([
      Validators.pattern("^(https:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?$"),
    ]);
    this.f.FooterDescription.setValidators([Validators.required]);
    this.f.CurrentTheme.setValidators([Validators.required]);
    this.f.TimeZone.setValidators([Validators.required]);
    this.f.FooterLink.setValidators([
      Validators.required,
      Validators.pattern("^(https?://).*$"),
    ]);
    this.f.SlackLink.setValidators([
      Validators.required,
      Validators.pattern("^https:\/\/[a-z0-9-]+\.slack\.com\/[a-zA-Z0-9-\/]*$"),
    ]);
    this.f.PhoneNo.setValidators([
      Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    ]);
    this.f.GlobalAnnouncement.setValidators([
      Validators.minLength(1),
      Validators.maxLength(200)
    ]);
    
  }

  checksubmitIncidentUrl() {
    this.f.CustomSubmitIncidentURL.setValidators([Validators.required]);
  }

  getFormattedTimestamp() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(now.getFullYear()).slice(2);
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${day}${month}${year}${hours}${minutes}${seconds}`;
  }


  // Loge Remove Code

  removeImage() {
    const formData = new FormData();
      formData.append("Logo", "assets/image/logodummy.svg");
      formData.append("LogoLink","assets/image/logodummy.svg");

    // Call the service to remove the image from the database
    this._adminApiService.GetUpdadeImg(formData).subscribe(
      (data) => {
        this.toastr.success(" Image Removed.", "Success", {
          closeButton: true,
        });
        if (data.success && data.result) {
          this.f.Id.setValue(data.result.Id);

        }
        this.imageSrc = 'assets/image/logodummy.svg?' + new Date().getTime();
        this.btnhideimg();
        this.router.navigate(["/admin/settings"]);
        AppComponent.hideLoader();
      },
      (error) => {
        if (error && error.error && error.error.message)
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        else
          this.toastr.error(
            "Something Went Wrong. Please contact Admin",
            "Error",
            { closeButton: true }
          );

        AppComponent.hideLoader();
      }
    );
  }
  btnhideimg(){
    this._adminApiService.GetSettings().subscribe((logoLink) => {
      console.log(logoLink)
      // console.log("API Response:", logoLink); // Log the entire response for inspection
        this.imageSrc = logoLink.result[0].logoLink; // Verify the property name
        // console.log("Image Path:", this.imageSrc); // Log the image path
        // console.log("ngOnInit",this.imageSrc);
        console.log(this.imageSrc)
        if (this.imageSrc == "assets/image/logodummy.svg") {
          this.hideButton = true;
        }
        else
        {
          this.hideButton = false;
        }
    });
  }


  isAdminRole() {
    return this._userService.GetUserPermission("admin");
  }

}




