import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusApiService } from 'src/app/api/statusapi.service';
import { Globals } from 'src/app/global';
import { Incident, Region, ReportData, StatusPageModel,IncidentMonthlyHistory } from 'src/app/models/StatusPageModel';
import { EventDetailDialogComponent } from '../event-detail-dialog/event-detail-dialog.component';
import { AdminApiService } from 'src/app/api/adminapi.service';


@Component({
  selector: 'app-single-region-home',
  templateUrl: './single-region-home.component.html',
  styleUrls: ['./single-region-home.component.css']
})
export class SingleRegionHomeComponent implements OnInit {
[x: string]: any;
  commonRegionName: string = "";
  statusData: StatusPageModel;
  incidentHistory: IncidentMonthlyHistory[];
  activeAnnouncements: Incident[];
  refreshClock: any;
  incidents: Incident[];
  maintenances: Incident[];
  searchText: string = "";

  incidentsHistory: Incident[];

  totalIncidents: number = 0;
  currentIncidents: number = 0;
  range: string = 'Last 7 days';
  componentAndGroupList:Array<Object> = [{ name: 'All', value: '' }];
  themeCookieValue: string = '';
  status: string = '';

  $currentDateTime: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));

  public templateDefault;

  toolTipProps = { placement: 'bottom', theme: 'dark', allowHTML: true };
  toolTipDarkProps = { placement: 'top', allowHTML: true };

  tipcontent: string = "<div class='p-2'><label><i class='bi bi-check-lg'> </i><span class='' > Operational </span>";
  tipcontentError: string = "<div class='p-3'><label>15 Dec 2021</label><br/><label class = 'mt-3 bg-light'><i class='bi bi-x-lg text-danger me-2'> </i><span class='me-3' > Partial Outage </span>";

  dtOptions: DataTables.Settings = Globals.homeDtOptions;
  refreshOptions: Array<Object> = [
    { name: '2 Minutes', value: "2" },
    { name: '5 Minutes', value: "5" },
    { name: '10 Minutes', value: "10" }
  ]
  refreshInterval: string = "2";
  timeZone;

  // Last Report
  range24: string = 'Last 24 hours';
  range30: string = 'Last 30 days';
  range7: string = 'Last 7 days';
  range90: string = 'Last 90 days';
  Historyrange :string = 'Last 7 days';
  region: string = '';
  component: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  fullRange = '';
  selectedTags = [];
  incidentsByUpTime: ReportData[];
  sevenDayrepprt = '';

  sevenDayReport: string = '100%';
  thirtyDayReport: string = '100%';
  ninetyDayReport: string = '100%';
  twentyFourHourReport: string = '100%';


  prepareReportsViewModel(range) {
    const reportsModel = <any>{};
    reportsModel.components = [];
    reportsModel.regions = [];
    reportsModel.range = range;
    reportsModel.isPublic = true;
    reportsModel.startDate = this.startDate;
    reportsModel.endDate = this.endDate;
    reportsModel.tags = this.selectedTags;

    if (this.region)
        reportsModel.regions.push(this.region);

    if (this.component)
        reportsModel.components.push(this.component);

    return reportsModel;
}


  getCookieValue() {
    this.themeCookieValue = this.cookieService.get('theme');
  }


  constructor(private _statusApiService: StatusApiService, public dialog: MatDialog,private _adminApiService: AdminApiService) {
    this.timeZone = Globals.timeZone;
    this.commonRegionName = Globals.commonRegionName;

  }

  getData(){
    const ranges = [this.range30, this.range7, this.range90,this.range24];

for (let i = 0; i < ranges.length; i++) {
  const currentRange = ranges[i];

  const requestPayload = this.prepareReportsViewModel(currentRange);

  this._adminApiService.GetUptimeReport(requestPayload)
    .subscribe(
      data => {
        if (data && data.success && data.result && data.result.incidentsByUpTime) {
          const percentage = data.result.incidentsByUpTime[0].percentage.toFixed(2);
          if (currentRange === this.range7) {
            this.sevenDayReport = `${percentage}%`;
          } else if (currentRange === this.range24) {
            this.twentyFourHourReport = `${percentage}%`;
          } else if (currentRange === this.range30) {
            this.thirtyDayReport = `${percentage}%`;
          } else if (currentRange === this.range90) {
            this.ninetyDayReport = `${percentage}%`;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
}

  }


  ngOnInit(): void {
    setTimeout(() => {
      this.getComponentStatus(true);
      this.getActiveAnnouncments();
      this.setUpdateRefreshClock();
    }, 1000);
    this.getData();
    this.getHistory();
  }

  getComponentStatus(updateRegionZones = false) {
    this._statusApiService.GetComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
              console.log(data.result)
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            this.maintenances = this.statusData.maintenances;
            console.log(data.result.componentGroups.components);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  setUpdateRefreshClock() {
    if (this.refreshClock)
      clearInterval(this.refreshClock);

    this.refreshClock = setInterval(() => {
      //console.log('executed : ' + this.refreshInterval + " " + new Date());
      this.getComponentStatus();
    }, parseInt(this.refreshInterval) * 1000 * 60);
  }


  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  openEventDetail(id) {
    const dialogRef = this.dialog.open(EventDetailDialogComponent,
      {
        panelClass: 'full-dialog',
        data: id,
        width: '70%',
        height: '60%'
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getFilterComponents(components, searchText){
    if(searchText){
      return components.filter(c=> c.componentName.toLowerCase().includes(searchText.toLowerCase()));
    }

    return components;
  }

  isAllSystemsOperational(): boolean {
    // Check your status data and return true if all systems are operational, false otherwise
    return this.statusData && this.statusData.componentGroups.every(group =>
      group.components.every(item => item.componentStatus[0]?.status?.toLowerCase() === 'operational')
    );
  }


  ///// Last 7 days History //////

  prepareHistoryViewModel() {
    const historyModel = <any>{};
    historyModel.Components = [];
    historyModel.Regions = [];
    historyModel.range = this.range;
    historyModel.isPublic = true;
    historyModel.skip = this.incidents ? this.getCurrentNumberOfRecords() : 0;
    historyModel.take = 10;

    if (this.region)
      historyModel.regions.push(this.region);

    if (this.component)
      historyModel.Components.push(this.component);

    return historyModel;
  }

  getHistory() {
    const requestPayload = this.prepareHistoryViewModel();
    console.log(requestPayload);
    this._statusApiService.GetStatusHistory(requestPayload)
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.incidentHistoryByMonthAndYear) {
            if (this.incidentsHistory && this.incidentsHistory.length > 0) {
              data.result.incidentHistoryByMonthAndYear.forEach((item) => {
                let filter = this.incidentsHistoryHistry.filter(x => x.monthAndYear == item.monthAndYear);
                if (filter && filter.length > 0) {
                  filter[0].incidentHistory.push(...item.incidentHistory)
                }
                else {
                  this.incidentsHistory.push(item);
                }
              });
            }
            else {
              this.incidentsHistory = data.result.incidentHistoryByMonthAndYear;
            }
            this.totalincidentsHistory = data.result.totalincidentsHistory
            // this.getCurrentNumberOfRecords();
          }
        },
        error => {
          console.log(error);
        }
      );
  }
  // getCurrentNumberOfRecords() {
  //   this.currentincidentsHistory = 0;
  //   this.incidentHistory.forEach((item) => {
  //     this.currentincidentsHistory += item.incidentHistory.length;
  //   });
  //   return this.currentincidentsHistory;
  // }


  viewMore() {
    this.getHistory();
  }
}
