import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { UserService } from '../../../api/user.service';
import { AdminApiService } from '../../../api/adminapi.service';
import { data } from 'jquery';
import { sharedService } from '../../../api/sharedService';
import { MatDialog } from '@angular/material/dialog';
import { FeatureRequestmodalsComponent } from "../feature-requestmodals/feature-requestmodals.component";

@Component({
  selector: 'admin-nav-menu',
  templateUrl: './admin-nav-menu.component.html',
  styleUrls: ['./admin-nav-menu.component.css'],
})
export class AdminNavMenuComponent implements OnInit {
  @Input() tippyProps: any;
  toolTipProps = { placement: 'right' };
  imageSrc: 'assets/image/logo-itechops.png';
  LogoPath: any = 'assets/image/logodummy.svg';

  constructor(
    private _userService: UserService,
    private _sharedService: sharedService,
    public dialog: MatDialog,
    private adminApiService: AdminApiService
  ) { }

  ngOnInit() {
    this._sharedService.getSetting().subscribe((logoPath) => {
      this.LogoPath = logoPath;
    });
    this.adminApiService.GetSettings().subscribe((data) => {
      const details = data.result[0];
       this.LogoPath = details?.logoLink;
    });
  }


  logoClose() {
    $("#sidebartext").hide();
    $(".main").addClass("maintwo");
  }

  logoOpen() {
    $("#sidebartext").show();
    $(".main").removeClass("maintwo");
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FeatureRequestmodalsComponent, {
      width: '400px',
      data: { title: 'My Modal', content: 'This is the content of my modal.' }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  IsAdminRole() {
    return this._userService.GetUserPermission("admin");
  }
}

