import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CGItemNode, CGRZItem, ItemNode, RZItem } from "./treeview";


  
@Injectable()
export class CGRZChecklistDatabase {
  dataChange = new BehaviorSubject<CGItemNode[]>([]);

  get data(): CGItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
  }

  initialize(TREE_DATA) {
    // Build the tree nodes from Json object. The result is a list of `ItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `ItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): CGItemNode[] {
    return Object.keys(obj).reduce<CGItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new CGItemNode();

      if (value != null) {
        if (typeof value === 'object') {
          const cgrz = new CGRZItem();
          cgrz.componentGroupId = value.componentGroupId;
          cgrz.componentGroupName = value.componentGroupName;
          cgrz.name = value.componentGroupName;
          node.item = cgrz;
          if (value.components)
            node.children = this.buildComponentFileTree(value.components,value, level + 1);
        } 
      }

      return accumulator.concat(node);
    }, []);
  }

  buildComponentFileTree(obj: { [key: string]: any }, cg, level: number): CGItemNode[] {
    return Object.keys(obj).reduce<CGItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new CGItemNode();

      if (value != null) {
        if (typeof value === 'object') {
          const cgrz = new CGRZItem();
          cgrz.componentGroupId = cg.componentGroupId;
          cgrz.componentGroupName = cg.componentGroupName;
          cgrz.componentId = value.componentId;
          cgrz.componentName = value.componentName;
          cgrz.name = value.componentName;

          node.item = cgrz;
          if (value.componentStatus)
            node.children = this.buildStatusFileTree(value.componentStatus, cg, value, level + 1);
        } 
      }

      return accumulator.concat(node);
    }, []);
  }

  buildStatusFileTree(obj: { [key: string]: any }, cg, c, level: number): CGItemNode[] {
    return Object.keys(obj).reduce<CGItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new CGItemNode();

      if (value != null) {
        if (typeof value === 'object') {
          const cgrz = new CGRZItem();
          cgrz.componentGroupId = cg.componentGroupId;
          cgrz.componentGroupName = cg.componentGroupName;
          cgrz.componentId = c.componentId;
          cgrz.componentName = c.componentName;
          cgrz.zoneId = value.zoneId;
          cgrz.zoneName = value.zoneName;
          cgrz.regionId = value.regionId;
          cgrz.regionName = value.regionName;

          if (value.zoneName != "Non-Regional")
            cgrz.name = value.regionName + " - " + value.zoneName;
          else
            cgrz.name = value.regionName;

          node.item = cgrz;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: CGItemNode, item: any) {
    if (parent.children) {
      parent.children.push({item: item} as CGItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: CGItemNode, item: any) {
    node.item = item;
    this.dataChange.next(this.data);
  }
}
